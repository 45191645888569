import ReactPixel from 'react-facebook-pixel'
export const handleBlogClick = (url: string, id: number) => {
  try {
    ReactPixel.trackCustom('CLICK_BLOG', {
      content_url: url,
      user_id: id,
    })
  } catch (e) {
    console.error('CLICK_BLOG PIXEL FAIL')
  }
}

export const handleCreateVideoClick = (id: number) => {
  try {
    ReactPixel.trackCustom('CLICK_CREATE_VIDEO_BY_BLOG', {
      user_id: id,
    })
  } catch (e) {
    console.error('CLICK_CREATE_VIDEO_BY_BLOG PIXEL FAIL')
  }
}
