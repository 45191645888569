import React, { useEffect, useState } from 'react'
import './App.css'
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import Home from './pages/Home'
import SignUp from './pages/SignUp'
import Header from './components/layout/Header'
import { Footer } from './components/layout/Footer'
import { Layout } from './components/layout/Layout'
import { SignIn } from './pages/SignIn'
import useUserStore from './store/useUserStore'
import { Youtube } from './pages/Youtube'
import { Video } from './pages/Video'
import { Terms } from './pages/Terms'
import { PriceGuide } from './pages/PriceGuide'
import { MyPayments } from './pages/MyPayments'
import { MyVideos } from './pages/MyVideos'
import Authentication from './components/Authentication/Authentication'
import { QnA } from './pages/QnA'
import { PaymentFail } from './pages/PaymentFail'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { MyCashDashboard } from './pages/MyCashDashboard'
import { ShortVideo } from './pages/ShortVideo'
import ReactPixel from 'react-facebook-pixel'
import {
  completeRegistration,
  getCookieValue,
  signUpBtnClick,
} from './utils/metaAd'
import {
  getUtmLocalStorage,
  removeLocalStorage,
  saveLocalStorage,
} from './utils/common'
import { isMobile } from 'react-device-detect'
import { EventPage } from './pages/EventPage'
import { MyPage } from './pages/MyPage'
import { Modal } from './components/layout/Modal'
import { CouponPack } from './pages/CouponPack'

const queryClient = new QueryClient()

const ScrollToTop = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

function App() {
  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const utm_source = params.get('utm_source')
  const utm_campaign = params.get('utm_campaign')
  const affiliate_source = params.get('affiliate_source')
  const affiliate_campaign = params.get('affiliate_campaign')
  const invite_code = params.get('invite_code')
  const userType = params.get('userType')
  const [isWelcomeModal, setWelcomeModal] = useState<boolean>(
    userType === 'true' ? true : false
  )
  const { pathname } = useLocation()

  const isInAppBrowser = (): boolean => {
    const userAgent: string =
      navigator.userAgent || navigator.vendor || (window as any).opera
    return /FBAN|FBAV|Instagram|Line|KAKAOTALK/i.test(userAgent)
  }
  if (utm_source) {
    saveLocalStorage('utm_source', utm_source)
    saveLocalStorage('utm_campaign', utm_campaign)
  }
  if (affiliate_source) {
    saveLocalStorage('affiliate_source', affiliate_source)
    saveLocalStorage('affiliate_campaign', affiliate_campaign)
  }
  if (invite_code) {
    saveLocalStorage('invite_code', invite_code)
  }

  useEffect(() => {
    // 고급옵션 사용자의 정보를 넣을 수 있음
    // const adMatching = {
    //   em: 'hyunse.oh@gmail.com',
    //   country: 'ko',
    //   db: '19990101',
    //   fn: 'ye',
    //   ln: 'dio',
    //   ge: 'f',
    //   ph: '01022223333',
    //   ct: '',
    //   st: '',
    //   zp: '',
    // }
    const options = {
      autoConfig: true, // 자동 구성
      debug: true, // 디버그 모드 활성화
    }

    ReactPixel.init(
      process.env.REACT_APP_META_AD_KEY
        ? process.env.REACT_APP_META_AD_KEY
        : '',
      undefined,
      options
    ) // 픽셀 ID로 초기화
    ReactPixel.pageView() // 페이지 뷰 추적
  }, [])

  useEffect(() => {
    const userTypeBool = userType === 'true' ? true : false
    if (userTypeBool) {
      // sign-up 이벤트 찍기  / utm있거나 / fbq가 있을때
      const utm = getUtmLocalStorage()
      if (utm?.utmSource) {
        completeRegistration()
      } else {
        signUpBtnClick()
      }
    }
  }, [userType])

  useEffect(() => {
    if (isMobile) {
      const isInApp = isInAppBrowser()
      if (isInApp) {
        const url = window.location.href // 현재 페이지 URL
        const userAgent: string =
          navigator.userAgent || navigator.vendor || (window as any).opera
        if (/android/i.test(userAgent)) {
          // 크롬에서 열기 위한 intent URL
          const intentUrl = `intent://${url.replace(
            /^https?:\/\//,
            ''
          )}#Intent;scheme=https;package=com.android.chrome;end;`
          window.location.href = intentUrl // 외부 브라우저로 열기
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
          // iOS 기기에서 외부 브라우저로 URL 열기

          window.location.href =
            'kakaotalk://web/openExternal?url=' + encodeURIComponent(url)
        }
      }
    }
  }, [isMobile])

  useEffect(() => {
    if (userInfo && !userInfo?.affiliate?.point) {
      removeLocalStorage('affiliate_source')
      removeLocalStorage('affiliate_campaign')
    }
  }, [userInfo])

  return (
    <QueryClientProvider client={queryClient}>
      <ToastContainer />
      <ScrollToTop />
      <Header />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/video-editor" element={<Video />} />
          <Route path="/short-video-editor" element={<ShortVideo />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/my-payments" element={<MyPayments />} />
          <Route path="/youtube-auth" element={<Youtube />} />
          <Route path="/QnA" element={<QnA />} />
          <Route path="/event-page" element={<EventPage />} />
          <Route path="/my-page" element={<MyPage />} />
          <Route path="/my-videos" element={<MyVideos />} />
          <Route path="/payment-fail" element={<PaymentFail />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/price-guide" element={<PriceGuide />} />
          <Route path="/my-cash-dashboard" element={<MyCashDashboard />} />
          <Route path="/coupon-pack" element={<CouponPack />} />
          <Route path="/*" element={<Home />} />
        </Routes>
        <Modal isModal={isWelcomeModal}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">🎉 환영합니다!</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">
                  패스트컷 회원이 되신 기념으로
                  <br /> 무료 캐시를 적립해드렸어요!
                  <br /> 캐시 관리 메뉴에서 캐시 내역 확인해보시고
                  <br /> 숏폼 만들기에 사용하세요.
                </p>
              </div>
            </div>
          </div>
          <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-full h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                if (userInfo?.userInfo && !userInfo?.userInfo?.termsAccepted) {
                  setWelcomeModal(false)
                  navigate('/terms')
                } else {
                  setWelcomeModal(false)
                }
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      </Layout>
      <Footer />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

export default App
