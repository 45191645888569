import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { checkBlogExists, checkTiktokExists } from '../../../service/event'

export const useCheckTiktokExistence = () => {
  return useMutation({
    mutationKey: ['check-tiktok-existence'],
    mutationFn: checkTiktokExists,
  })
}
