import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Controller, set, useForm } from 'react-hook-form'
import { Input } from '../atom/Input'
import { ErrorResponse, useNavigate } from 'react-router-dom'
import useUserStore from '../../store/useUserStore'
import { useCheckBlogExistence } from '../../hooks/query/event/useCheckBlogExistence'
import { useCheckYoutubeExistence } from '../../hooks/query/event/useCheckYoutubeExistence'
import { checkYoutubeExists, EventBlogType } from '../../service/event'
import { useCheckInstagramExistence } from '../../hooks/query/event/useCheckInstagramExistence'
import { useCheckTiktokExistence } from '../../hooks/query/event/useCheckTiktokExistence'
import {
  checkBlogExists,
  checkClipExists,
  extractTikTokURL,
} from '../../utils/common'
import { Modal } from '../layout/Modal'
import useSaveBlog from '../../hooks/query/event/useSaveBlog'
import useGetEventBlog from '../../hooks/query/event/useGetEventBlog'
import useAuthChannel from '../../hooks/query/event/useAuthChannel'
import { handleBlogClick, handleCreateVideoClick } from '../../utils/blogEvent'

export const MobileBlogEvent = () => {
  // 기존 정보
  const { data: blogData, refetch } = useGetEventBlog()

  // 영상 생성 요청
  const { mutate: saveMutate, isPending: savePending } = useSaveBlog()

  // 채널 인증 요청
  const { mutate: authChannelMutate, isPending: authChannelPending } =
    useAuthChannel()

  /**
   * 요청 api
   *  blog / youtube / instagram / tiktok
   *  주소들이 유효한지 검증하는 api
   *  @return og values
   */
  const { mutate: checkBlogExistence, isPending: checkBlogExistencePending } =
    useCheckBlogExistence()
  const { mutate: checkYoutubeMutate, isPending: checkYoutubePending } =
    useCheckYoutubeExistence()
  const { mutate: checkInstagramMutate, isPending: checkInstagramPending } =
    useCheckInstagramExistence()

  const { mutate: checkTiktokMutate, isPending: checkTiktokPending } =
    useCheckTiktokExistence()

  const navigate = useNavigate()
  const userInfo = useUserStore((state: any) => state.userInfo)

  const [isCrawlingLoading, setCrawlingLoading] = useState<boolean>(false)

  // blog정보
  const [blogInfo, setBlogInfo] = useState<any>(null)
  const [isCheckBlogExistenceError, setCheckBlogExistenceError] =
    useState<boolean>(false)

  // 인스타그램 정보
  const [authInstagramInfo, setAuthInstagramInfo] = useState<any>(null)
  const [instagramInfo, setInstagramInfo] = useState<any>(null)
  const [isCheckInstagram, setCheckInstagram] = useState<boolean>(false)
  const [isAuthCheckInstagram, setAuthCheckInstagram] = useState<boolean>(false)

  // 유튜브 정보
  const [youtubeInfo, setYoutubeInfo] = useState<any>(null)
  const [authYoutubeInfo, setAuthYoutubeInfo] = useState<any>(null)
  const [isCheckYoutube, setCheckYoutube] = useState<boolean>(false)
  const [isAuthCheckYoutube, setAuthCheckYoutube] = useState<boolean>(false)

  // tiktok 정보
  const [authTiktokInfo, setAuthTiktokInfo] = useState<any>(null)
  const [tiktokInfo, setTiktokInfo] = useState<any>(null)
  const [isCheckTiktok, setCheckTiktok] = useState<boolean>(false)
  const [isAuthCheckTiktok, setAuthCheckTiktok] = useState<boolean>(false)

  // clip
  const [authClipInfo, setAuthClipInfo] = useState<any>(null)
  const [clipInfo, setClipInfo] = useState<any>(null)
  const [isCheckClip, setCheckClip] = useState<boolean>(false)
  const [isAuthCheckClip, setAuthCheckClip] = useState<boolean>(false)

  //요청 성공
  const [isExistData, setExistData] = useState<boolean>(false)
  const [isAuth, setAuth] = useState<boolean>(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const [isAuthSuccess, setAuthSuccess] = useState<boolean>(false)
  const [isCheckSave, setCheckSave] = useState<boolean>(false)
  const [saveData, setSaveData] = useState<null | EventBlogType[]>()

  const [youtubeId, setYoutubeId] = useState<null | number>(null)
  const [instagramId, setInstagramId] = useState<null | number>(null)
  const [tiktokId, setTiktokId] = useState<null | number>(null)
  const [clipId, setClipId] = useState<null | number>(null)
  //유튜브 / 틱톡 / 인스타그램
  const {
    control: authenticationControl,
    handleSubmit: authenticationHandleSubmit,
    watch: authenticationWatch,
    setValue: authenticationSetvalue,
    formState: { errors: authenticationErrors },
  } = useForm({
    defaultValues: {
      youtubeUrl: '',
      instagramUrl: '',
      tiktokUrl: '',
      clipUrl: '',
    },
  })
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      blogUrl: '',
      youtubeUrl: '',
      instagramUrl: '',
      tiktokUrl: '',
      clipUrl: '',
    },
  })

  const handleAuthChannel = (data: any) => {
    const {
      youtubeUrl: authYoutubeUrl,
      instagramUrl: authInstagramUrl,
      tiktokUrl: authTiktokUrl,
      clipUrl: authClipUrl,
    } = data
    const blogEvent: EventBlogType[] = []

    if (youtubeInfo && youtubeId) {
      if (authYoutubeInfo) {
        //step2에서 유튜브를 등록 후 step3에서 수정
        blogEvent.push({
          id: youtubeId,
          type: 'channel',
          service: 'youtube',
          link: authYoutubeUrl,
          etc: authYoutubeInfo,
        })
      }
    } else {
      // step2에서 등록하지 않고 step3에서 등록

      if (authYoutubeInfo) {
        blogEvent.push({
          id: 0,
          type: 'channel',
          service: 'youtube',
          link: authYoutubeUrl,
          etc: authYoutubeUrl,
        })
      }
    }

    if (instagramInfo && instagramId) {
      if (authInstagramInfo) {
        //step2에서 인스타를 등록 후 step3에서 수정
        blogEvent.push({
          id: instagramId,
          type: 'channel',
          service: 'instagram',
          link: authInstagramUrl,
          etc: authInstagramInfo,
        })
      }
    } else {
      // step2에서 인스타 등록하지 않고 step3에서 인스타 등록
      if (authInstagramInfo) {
        blogEvent.push({
          id: 0,
          type: 'channel',
          service: 'instagram',
          link: authInstagramUrl,
          etc: authInstagramInfo,
        })
      }
    }

    if (tiktokInfo && tiktokId) {
      if (authTiktokInfo) {
        //step2에서 인스타를 등록 후 step3에서 수정
        blogEvent.push({
          id: tiktokId,
          type: 'channel',
          service: 'tiktok',
          link: authTiktokUrl,
          etc: authTiktokInfo,
        })
      }
    } else {
      // step2에서 인스타 등록하지 않고 step3에서 인스타 등록
      if (authTiktokInfo) {
        blogEvent.push({
          id: 0,
          type: 'channel',
          service: 'tiktok',
          link: authTiktokUrl,
          etc: authTiktokInfo,
        })
      }
    }

    if (clipInfo && clipId) {
      if (authClipInfo) {
        //step2에서 인스타를 등록 후 step3에서 수정
        blogEvent.push({
          id: clipId,
          type: 'channel',
          service: 'naver',
          link: authClipInfo?.originUrl ?? authClipUrl,
          etc: authClipInfo,
        })
      }
    } else {
      // step2에서 인스타 등록하지 않고 step3에서 인스타 등록
      if (authClipInfo) {
        blogEvent.push({
          id: 0,
          type: 'channel',
          service: 'tiktok',
          link: authClipInfo?.originUrl ?? authClipUrl,
          etc: authClipInfo,
        })
      }
    }

    authChannelMutate(blogEvent, {
      onSuccess: () => {
        setAuthSuccess((prev) => !prev)
      },
      onError: () => {},
    })
  }

  const handleSaveSubmit = (data: any) => {
    const { blogUrl, youtubeUrl, instagramUrl, tiktokUrl, clipUrl } = data
    const blogEvent = []
    if (blogUrl && blogInfo) {
      const service =
        blogUrl.includes('blog.naver.com') || blogUrl.includes('naver.me')
          ? 'naver'
          : blogUrl.includes('tistory.com')
          ? 'tistory'
          : blogInfo?.serviceType && blogInfo?.serviceType

      blogEvent.push({
        type: 'blog',
        service: service,
        link: blogInfo?.originUrl ?? blogUrl,
        etc: blogInfo,
      })
    }
    if (youtubeUrl && youtubeInfo) {
      blogEvent.push({
        type: 'channel',
        service: 'youtube',
        link: youtubeUrl,
        etc: youtubeInfo,
      })
    }
    if (instagramUrl && instagramInfo) {
      blogEvent.push({
        type: 'channel',
        service: 'instagram',
        link: instagramUrl,
        etc: instagramInfo,
      })
    }
    if (tiktokUrl && tiktokInfo) {
      blogEvent.push({
        type: 'channel',
        service: 'tiktok',
        link: tiktokUrl,
        etc: tiktokInfo,
      })
    }
    if (clipUrl && clipInfo) {
      blogEvent.push({
        type: 'channel',
        service: 'naver',
        link: clipInfo?.originUrl ?? clipUrl,
        etc: clipInfo,
      })
    }
    setSaveData(blogEvent)
    setCheckSave(true)
  }
  const onSubmit = () => {
    if (saveData) {
      saveMutate(saveData, {
        onSuccess: () => {
          handleCreateVideoClick(userInfo?.userInfo?.id ?? 0)
          setCheckSave(false)
          setSuccess(true)
        },
        onError: () => {},
      })
    }
  }

  const handleAuthCheck = () => {
    if (
      !authYoutubeInfo &&
      !authInstagramInfo &&
      !authTiktokInfo &&
      !authClipInfo
    ) {
      return true
    }
    return false
  }

  const userCheck = () => {
    if (!userInfo) {
      navigate('/sign-up')
      return
    } else {
      return true
    }
  }
  const handleCheckBlogUrl = async () => {
    const url = watch('blogUrl')

    if (url && url.startsWith('https://')) {
      const transformUrl = checkBlogExists(url)
      setValue('blogUrl', transformUrl)
      checkBlogExistence(transformUrl, {
        onSuccess: (data) => {
          handleBlogClick(transformUrl, userInfo?.userInfo?.id ?? 0)
          setBlogInfo(data)

          setCheckBlogExistenceError(false)
        },
        onError: (e: any) => {
          setCheckBlogExistenceError(true)
        },
      })
    } else {
      setCheckBlogExistenceError(true)
    }
  }

  const handleCheckClipUrl = async () => {
    const url = watch('clipUrl')

    if (url && url.startsWith('https://') && url.includes('naver')) {
      const transformUrl = checkClipExists(url)
      setValue('clipUrl', transformUrl)
      checkBlogExistence(transformUrl, {
        onSuccess: (data) => {
          setClipInfo(data)
          setCheckClip(false)
        },
        onError: (e: any) => {
          setCheckClip(true)
        },
      })
    } else {
      setCheckClip(true)
    }
  }
  const handleAuthCheckClipUrl = async () => {
    const url = authenticationWatch('clipUrl')
    if (url && url.startsWith('https://') && url.includes('naver')) {
      checkBlogExistence(url, {
        onSuccess: (data) => {
          setAuthClipInfo(data)
          setAuthCheckClip(false)
        },
        onError: (e: any) => {
          setAuthCheckClip(true)
        },
      })
    } else {
      setAuthCheckClip(true)
    }
  }
  const handleCheckYoutubeUrl = () => {
    const url = watch('youtubeUrl')
    if (
      url &&
      (url.startsWith('https://www.youtube.com/@') ||
        url.startsWith('https://youtube.com/@'))
    ) {
      checkYoutubeMutate(url, {
        onSuccess: (data) => {
          setYoutubeInfo(data)
          setCheckYoutube(false)
        },
        onError: (e: any) => {
          setCheckYoutube(true)
        },
      })
    } else {
      setCheckYoutube(true)
    }
  }
  const handleAuthCheckYoutubeUrl = () => {
    const url = authenticationWatch('youtubeUrl')
    if (
      url &&
      (url.startsWith('https://www.youtube.com/@') ||
        url.startsWith('https://youtube.com/@'))
    ) {
      checkYoutubeMutate(url, {
        onSuccess: (data) => {
          setAuthYoutubeInfo(data)
          setAuthCheckYoutube(false)
        },
        onError: (e: any) => {
          setAuthCheckYoutube(true)
        },
      })
    } else {
      setAuthCheckYoutube(true)
    }
  }
  const handleCheckInstagramUrl = () => {
    const url = watch('instagramUrl')
    const postUrl = 'https://www.instagram.com/p'
    if (url.startsWith(postUrl)) return setCheckInstagram(true)
    if (url && url.startsWith('https://www.instagram.com')) {
      checkInstagramMutate(url, {
        onSuccess: (data) => {
          setInstagramInfo(data)
          setCheckInstagram(false)
        },
        onError: (e: any) => {
          setCheckInstagram(true)
        },
      })
    } else {
      setCheckInstagram(true)
    }
  }

  const handleAuthCheckInstagramUrl = () => {
    const url = authenticationWatch('instagramUrl')
    const postUrl = 'https://www.instagram.com/p'
    if (url.startsWith(postUrl)) return setAuthCheckInstagram(true)
    if (url && url.startsWith('https://www.instagram.com')) {
      checkInstagramMutate(url, {
        onSuccess: (data) => {
          setAuthInstagramInfo(data)
          setAuthCheckInstagram(false)
        },
        onError: (e: any) => {
          setAuthCheckInstagram(true)
        },
      })
    } else {
      setAuthCheckInstagram(true)
    }
  }
  const handleCheckTiktokUrl = () => {
    const url = watch('tiktokUrl')
    if (url && url.startsWith('https://www.tiktok.com')) {
      const extractUrl = extractTikTokURL(url)
      setValue('tiktokUrl', extractUrl)
      checkTiktokMutate(extractUrl, {
        onSuccess: (data) => {
          setTiktokInfo(data)
          setCheckTiktok(false)
        },
        onError: (e: any) => {
          setCheckTiktok(true)
        },
      })
    } else {
      setCheckTiktok(true)
    }
  }
  const handleAuthCheckTiktokUrl = () => {
    const url = authenticationWatch('tiktokUrl')
    if (url && url.startsWith('https://www.tiktok.com')) {
      const extractUrl = extractTikTokURL(url)
      authenticationSetvalue('tiktokUrl', extractUrl)
      checkTiktokMutate(extractUrl, {
        onSuccess: (data) => {
          setAuthTiktokInfo(data)
          setAuthCheckTiktok(false)
        },
        onError: (e: any) => {
          setAuthCheckTiktok(true)
        },
      })
    } else {
      setAuthCheckTiktok(true)
    }
  }
  const handleExitButton = (path: string) => {
    if (path === 'blogUrl') {
      setValue('blogUrl', '')
      setBlogInfo(null)
      setCheckBlogExistenceError(false)
    } else if (path === 'youtubeUrl') {
      setValue('youtubeUrl', '')
      setYoutubeInfo(null)
      setCheckYoutube(false)
    } else if (path === 'instagramUrl') {
      setValue('instagramUrl', '')
      setInstagramInfo(null)
      setCheckInstagram(false)
    } else if (path === 'tiktokUrl') {
      setValue('tiktokUrl', '')
      setTiktokInfo(null)
      setCheckTiktok(false)
    } else if (path === 'clipUrl') {
      setValue('clipUrl', '')
      setClipInfo(null)
      setCheckClip(false)
    }
  }
  const handleAuthExitButton = (path: string) => {
    if (path === 'youtubeUrl') {
      authenticationSetvalue('youtubeUrl', '')
      setAuthCheckYoutube(false)
      setAuthYoutubeInfo(null)
    } else if (path === 'instagramUrl') {
      authenticationSetvalue('instagramUrl', '')
      setAuthInstagramInfo(null)
      setAuthCheckInstagram(false)
    } else if (path === 'tiktokUrl') {
      authenticationSetvalue('tiktokUrl', '')
      setAuthTiktokInfo(null)
      setAuthCheckTiktok(false)
    } else if (path === 'clipUrl') {
      authenticationSetvalue('clipUrl', '')
      setAuthClipInfo(null)
      setAuthCheckClip(false)
    }
  }

  useEffect(() => {
    //기존의 데이터가 있으면 전부 셋팅해주어야함
    //1. blog
    if (blogData && blogData.length > 0) {
      const [blog] = blogData.filter((el) => el.type === 'blog')
      setBlogInfo(blog?.etc)
      setValue('blogUrl', blog?.link)

      const [youtube] = blogData.filter((el) => el.service === 'youtube')
      setYoutubeInfo(youtube?.etc)
      setValue('youtubeUrl', youtube?.link)
      authenticationSetvalue('youtubeUrl', youtube?.link)
      if (youtube?.id) setYoutubeId(youtube.id)

      const [instagram] = blogData.filter((el) => el.service === 'instagram')
      setInstagramInfo(instagram?.etc)
      setValue('instagramUrl', instagram?.link)
      authenticationSetvalue('instagramUrl', instagram?.link)
      if (instagram?.id) setInstagramId(instagram.id)

      const [tiktok] = blogData.filter((el) => el.service === 'tiktok')
      setTiktokInfo(tiktok?.etc)
      setValue('tiktokUrl', tiktok?.link)
      authenticationSetvalue('tiktokUrl', tiktok?.link)
      if (tiktok?.id) setTiktokId(tiktok.id)

      const [clip] = blogData.filter(
        (el) => el.service === 'naver' && el.type === 'channel'
      )

      setClipInfo(clip?.etc)
      setValue('clipUrl', clip?.link)
      authenticationSetvalue('clipUrl', clip?.link)
      if (clip?.id) setClipId(clip.id)

      //인증이 되어있는 조건
      if (
        youtube?.status === 'check' ||
        instagram?.status === 'check' ||
        tiktok?.status === 'check' ||
        clip?.status === 'check'
      ) {
        setAuth(true)
      }
      setExistData(true)
    }
  }, [blogData])
  return (
    <div className="pt-[20px] w-full my-0 mx-auto flex flex-col items-center ">
      <div className="flex flex-col items-center gap-[24px]  mb-[40px] md:mb-10">
        <div className="flex flex-col items-center text-[32px]">
          <p>
            아직도 <span className="font-bold">블로그</span>만 하세요?{' '}
          </p>
          <p className="text-center">
            <span className="font-bold">유튜브, 릴스, 틱톡</span>
            <br /> 한 방에 진출!
          </p>
        </div>
        <div className="blog_icons flex items-center gap-4">
          <img
            src="assets/img/blog_event/naver.png"
            alt="icons"
            className="w-[71px] h-[29px]"
          />
          <img
            src="assets/img/blog_event/tistory.png"
            alt="icons"
            className="w-[101px] h-[29px]"
          />
        </div>
        <div className="blog_to_short">
          <img
            src="assets/img/blog_event/blog_to_short.png"
            alt="icons"
            className="w-[335px] h-[40px]"
          />
        </div>

        <div className="main_img">
          <img
            src="assets/img/blog_event/main.png"
            alt="icons"
            className="w-[335px]"
          />
        </div>
      </div>
      <div className="home_first flex flex-col items-center gap-[16px] mb-10 w-[335px]">
        <div className=" bg-[#2E343D] text-[white] rounded-[20px] p-5 flex flex-col gap-4">
          <div className="title ">
            <p className="font-bold text-[18px]">
              💁🏻 10,000캐시 추가적립 상세 안내
            </p>
          </div>
          <div className="list flex flex-col gap-4 text-[14px]">
            <p className="">
              <span className="font-bold"> 🗓 참여 기간</span> : 10월 29일 ~
              추후공지
            </p>

            <p>
              <span className="font-bold"> 🎯 참여 자격</span> : 패스트컷 AI
              회원 + 블로그 링크 입력 + 생성된 영상을 기간 내 업로드/인증
            </p>
            <p>
              <span className="font-bold"> 💰 업로드 인증</span>: 아래 STEP3에서
              인증하면 관리자가 확인
            </p>

            <p>
              <span className="font-bold"> 💸 혜택 </span>: 업로드 인증 확인
              완료된 계정에 한해 10,000캐시(사용기한 1개월) 적립
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center gap-[24px] w-[335px] mb-[40px] md:mb-10">
        <div className="step_img">
          <img src="assets/img/blog_event/step.png" alt="step" />
        </div>
      </div>
      <form
        onSubmit={handleSubmit(handleSaveSubmit, (err) => {
          console.log(err)
        })}
      >
        <div
          className={classNames(
            'step_description flex flex-col items-center gap-10 w-full  mb-10 shadow-main p-5 ',
            {
              'rounded-[40px]': !isMobile,
            }
          )}
        >
          <div className="step1 flex flex-col w-[335px] gap-4  ">
            <p className={classNames(' font-bold w-full text-[24px]', {})}>
              STEP1. 블로그 주소를 입력해주세요!
            </p>
            <div className="flex flex-col ">
              <p className="text-main text-[14px] mb-2">
                <span className="font-bold">네이버 블로그, 티스토리</span>만
                동작하며, 저작권에 유의해주세요!{' '}
              </p>
              <p className="text-main text-[14px] mb-2">
                블로그에 <span className="font-bold">30개 이상의 포스트</span>가
                있어야하고, 각 포스트에는 이미지가 포함되어 있어야 이벤트에
                참여할 수 있어요.
              </p>
              <p>
                <div className=" relative">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="내 블로그의 대표 주소 입력 (예 https://blog.naver.com/blogpeople)"
                          className="bg-[#F6F8FB]"
                          error={isCheckBlogExistenceError}
                          disabled={blogInfo}
                          userCheck={userCheck}
                        />
                      </>
                    )}
                    name={'blogUrl'}
                    rules={{
                      required: {
                        value: true,
                        message: 'URL 입력은 필수입니다!',
                      },
                    }}
                    control={control}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleExitButton('blogUrl')
                      }}
                      disabled={isExistData}
                      type="button"
                    >
                      <img
                        className="w-[32x] h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      onClick={handleCheckBlogUrl}
                      disabled={
                        checkBlogExistencePending || blogInfo || isExistData
                      }
                      type="button"
                    >
                      {checkBlogExistencePending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '적용' // 적용 버튼 텍스트
                      )}
                    </button>
                  </div>
                </div>
              </p>
              {((blogInfo && !checkBlogExistencePending) ||
                isCheckBlogExistenceError) && (
                <div className="mt-2 text-[15px]">
                  {blogInfo?.ogp?.['og:image'][0] && (
                    <p className="flex gap-3 items-center">
                      <span>
                        <img
                          src={blogInfo?.ogp['og:image'][0]}
                          alt="img"
                          width={20}
                          height={20}
                          className=" rounded-[100px]"
                        />
                      </span>
                      <span className="font-bold ">Blog</span>
                    </p>
                  )}

                  {blogInfo?.ogp?.['og:title'][0] && (
                    <p className="font-bold text-[#2C649E]">
                      {blogInfo?.ogp['og:title'][0]}
                    </p>
                  )}
                  {blogInfo?.ogp?.['og:description'][0] && (
                    <p>{blogInfo?.ogp['og:description'][0]}</p>
                  )}

                  {isCheckBlogExistenceError && (
                    <p className="text-error text-[14px]">
                      사용할 수 없는 블로그 입니다
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="step2 flex flex-col w-[335px] gap-4">
            <div>
              <p className={classNames(' font-bold w-full text-[24px]', {})}>
                STEP2. 영상 업로드할 채널 주소를 입력해주세요!
              </p>
              <p className="text-error text-[14px]">(1개 이상 필수)</p>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* 유튜브 */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_youtube.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="채널 주소 입력 (예 https://www.youtube.com/@fastseller)"
                          className="bg-[#F6F8FB]"
                          error={isCheckYoutube}
                          disabled={youtubeInfo || isExistData}
                          userCheck={userCheck}
                        />
                      </>
                    )}
                    name={`youtubeUrl`}
                    control={control}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleExitButton('youtubeUrl')
                      }}
                      type="button"
                      disabled={isExistData}
                    >
                      <img
                        className="w-[32px] h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      onClick={handleCheckYoutubeUrl}
                      disabled={
                        checkYoutubePending || youtubeInfo || isExistData
                      }
                      type="button"
                    >
                      {checkYoutubePending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '적용'
                      )}
                    </button>
                  </div>
                </div>
                {((youtubeInfo && !checkYoutubePending) || isCheckYoutube) && (
                  <div className="flex flex-col gap-1  text-[15px]">
                    {youtubeInfo?.ogp?.['og:image'] && (
                      <p className="flex gap-3 items-center">
                        <span>
                          <img
                            src={youtubeInfo?.ogp['og:image'][0]}
                            alt="img"
                            width={20}
                            height={20}
                            className=" rounded-[100px]"
                          />
                        </span>
                        <span className="font-bold ">YouTube</span>
                      </p>
                    )}
                    {youtubeInfo?.ogp?.['og:title'] && (
                      <p className="font-bold text-[#2C649E]">
                        {youtubeInfo?.ogp['og:title'][0]}
                      </p>
                    )}
                    {youtubeInfo?.ogp?.['og:description'] && (
                      <p>{youtubeInfo?.ogp['og:description'][0]}</p>
                    )}

                    {isCheckYoutube && (
                      <p className="text-error text-[14px]">
                        찾을 수 없는 주소입니다.
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* instagram */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_instagram.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="프로필 링크 입력"
                          className="bg-[#F6F8FB]"
                          error={isCheckInstagram}
                          disabled={instagramInfo || isExistData}
                          userCheck={userCheck}
                        />
                      </>
                    )}
                    name={`instagramUrl`}
                    control={control}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleExitButton('instagramUrl')
                      }}
                      type="button"
                      disabled={isExistData}
                    >
                      <img
                        className="w-[32px] h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      onClick={handleCheckInstagramUrl}
                      disabled={
                        checkInstagramPending || instagramInfo || isExistData
                      }
                      type="button"
                    >
                      {checkInstagramPending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '적용'
                      )}
                    </button>
                  </div>
                </div>
              </>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* naver/clip*/}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_naver_clip.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="네이버 블로그 주소 입력 (예 https://blog.naver.com/blogpeople)"
                          className="bg-[#F6F8FB]"
                          error={isCheckClip}
                          disabled={clipInfo || isExistData}
                          userCheck={userCheck}
                        />
                      </>
                    )}
                    name={`clipUrl`}
                    control={control}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleExitButton('clipUrl')
                      }}
                      disabled={isExistData}
                      type="button"
                    >
                      <img
                        className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      onClick={handleCheckClipUrl}
                      disabled={
                        checkBlogExistencePending || clipInfo || isExistData
                      }
                      type="button"
                    >
                      {checkBlogExistencePending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '적용'
                      )}
                    </button>
                  </div>
                </div>
                {((clipInfo && !checkBlogExistencePending) || isCheckClip) && (
                  <div className="mt-2">
                    {clipInfo?.ogp?.['og:image'][0] && (
                      <p className="flex gap-3 items-center">
                        <span>
                          <img
                            src={clipInfo?.ogp['og:image'][0]}
                            alt="img"
                            width={20}
                            height={20}
                            className=" rounded-[100px]"
                          />
                        </span>
                        <span className="font-bold text-[18px]">Blog</span>
                      </p>
                    )}

                    {clipInfo?.ogp?.['og:title'][0] && (
                      <p className="font-bold text-[#2C649E]">
                        {clipInfo?.ogp['og:title'][0]}
                      </p>
                    )}
                    {clipInfo?.ogp?.['og:description'][0] && (
                      <p>{clipInfo?.ogp['og:description'][0]}</p>
                    )}

                    {isCheckClip && (
                      <p className="text-error text-[14px]">
                        사용할 수 없는 블로그 입니다
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* 틱톡 */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_tiktok.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="프로필 링크 입력"
                          className="bg-[#F6F8FB]"
                          error={isCheckTiktok}
                          disabled={tiktokInfo || isExistData}
                          userCheck={userCheck}
                        />
                      </>
                    )}
                    name={`tiktokUrl`}
                    control={control}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleExitButton('tiktokUrl')
                      }}
                      disabled={isExistData}
                      type="button"
                    >
                      <img
                        className="w-[32px] h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      onClick={handleCheckTiktokUrl}
                      disabled={checkTiktokPending || tiktokInfo || isExistData}
                      type="button"
                    >
                      {checkTiktokPending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '적용'
                      )}
                    </button>
                  </div>
                </div>
                {((tiktokInfo && !checkTiktokPending) || isCheckTiktok) && (
                  <div className="flex flex-col gap-1  text-[15px]">
                    {tiktokInfo?.['title'] && (
                      <span className="font-bold">TikTok</span>
                    )}

                    {tiktokInfo?.['title'] && (
                      <p className="font-bold text-[#2C649E]">
                        {tiktokInfo?.['title']}
                      </p>
                    )}
                    {tiktokInfo?.['desc'] && <p>{tiktokInfo?.['desc']}</p>}

                    {isCheckTiktok && (
                      <p className="text-error text-[14px]">
                        찾을 수 없는 주소입니다.
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>

          <div>
            <ul className=" list-decimal text-[14px] text-[#5E6068] ml-5">
              <li>
                블로그 주소 등록 및 영상 생성 요청은 1개의 계정당 1회
                가능합니다.
              </li>
              <li className="mt-2">
                영상은 생성 요청 후 이벤트 참여 순서대로, 참여자 현황에 따라
                며칠에 걸쳐 나누어 생성됩니다.
              </li>
              <li className="mt-2">
                생성된 영상은{' '}
                <span
                  className=" underline text-href font-bold cursor-pointer"
                  onClick={() => {
                    userCheck()
                    navigate('/my-videos')
                  }}
                >
                  사용현황
                </span>
                에서 다운로드 가능합니다.
              </li>
              <li className="mt-2">
                블로그에 30개 미만의 포스트가 있을 경우 영상 생성 대상에서
                제외됩니다.
              </li>
              <li className="mt-2">목소리, 템플릿 디자인은 랜덤 적용됩니다.</li>
              <li className="mt-2">
                동일인이 여러 계정으로 생성 요청 하는 등 어뷰징이 발견될 경우
                관리자 권한으로 제제합니다.
              </li>
            </ul>
          </div>
          <div
            className={classNames(
              'flex justify-center w-full  text-white rounded-[60px] font-bold  text-[18px] items-center mt-4 ',
              {
                'bg-[#3A8FF2]':
                  blogInfo &&
                  (youtubeInfo || instagramInfo || tiktokInfo || clipInfo),
                'bg-[#D9DEE8]':
                  !blogInfo ||
                  !(youtubeInfo || instagramInfo || tiktokInfo || clipInfo) ||
                  isExistData,
              }
            )}
          >
            <button
              className="w-full h-[60px]"
              type="submit"
              disabled={
                !blogInfo ||
                !(youtubeInfo || instagramInfo || tiktokInfo || clipInfo) ||
                isExistData
              }
            >
              입력완료, 영상 생성 요청
            </button>
          </div>
        </div>
      </form>

      <form
        onSubmit={authenticationHandleSubmit(handleAuthChannel, (err) => {
          console.log(err)
        })}
      >
        <div
          className={classNames(
            'step_description flex flex-col items-center gap-10 w-full  mb-10 shadow-main p-5',
            {
              'rounded-[40px]': !isMobile,
            }
          )}
        >
          <div className="step3 flex flex-col w-[335px] gap-4">
            <div>
              <p className={classNames(' font-bold w-full text-[24px]', {})}>
                STEP3. 채널에 영상을 등록하셨다면, 인증해주세요!
              </p>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* 유튜브 */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_youtube.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="채널 주소 입력"
                          className="bg-[#F6F8FB]"
                          error={isAuthCheckYoutube}
                          disabled={authYoutubeInfo || isAuth}
                          userCheck={userCheck}
                        />
                      </>
                    )}
                    name={`youtubeUrl`}
                    control={authenticationControl}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleAuthExitButton('youtubeUrl')
                      }}
                      disabled={isAuth}
                      type="button"
                    >
                      <img
                        className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#3A8FF2] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      type="button"
                      onClick={handleAuthCheckYoutubeUrl}
                      disabled={
                        checkYoutubePending ||
                        isAuth ||
                        authYoutubeInfo ||
                        !isExistData
                      }
                    >
                      {checkYoutubePending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '인증'
                      )}
                    </button>
                  </div>
                </div>
                {(isAuth ||
                  (authYoutubeInfo && !checkYoutubePending) ||
                  isAuthCheckYoutube) && (
                  <div className="flex flex-col gap-1  text-[15px]">
                    {youtubeInfo?.ogp?.['og:image'] && (
                      <p className="flex gap-3 items-center">
                        <span>
                          <img
                            src={
                              isAuth
                                ? youtubeInfo?.ogp['og:image'][0]
                                : authYoutubeInfo?.ogp['og:image'][0]
                            }
                            alt="img"
                            width={20}
                            height={20}
                            className=" rounded-[100px]"
                          />
                        </span>
                        <span className="font-bold ">YouTube</span>
                      </p>
                    )}
                    {youtubeInfo?.ogp?.['og:title'] && (
                      <p className="font-bold text-[#2C649E]">
                        {isAuth
                          ? youtubeInfo?.ogp['og:title'][0]
                          : authYoutubeInfo?.ogp['og:title'][0]}
                      </p>
                    )}
                    {youtubeInfo?.ogp?.['og:description'] && (
                      <p>
                        {' '}
                        {isAuth
                          ? youtubeInfo?.ogp['og:description'][0]
                          : authYoutubeInfo?.ogp['og:description'][0]}
                      </p>
                    )}

                    {isAuthCheckYoutube && (
                      <p className="text-error text-[14px]">
                        찾을 수 없는 주소입니다.
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* instagram */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_instagram.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="프로필 링크 입력"
                          className="bg-[#F6F8FB]"
                          userCheck={userCheck}
                          disabled={authInstagramInfo || isAuth}
                          error={isAuthCheckInstagram}
                        />
                      </>
                    )}
                    name={`instagramUrl`}
                    control={authenticationControl}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleAuthExitButton('instagramUrl')
                      }}
                      disabled={isAuth}
                      type="button"
                    >
                      <img
                        className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#3A8FF2] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      type="button"
                      onClick={handleAuthCheckInstagramUrl}
                      disabled={
                        checkInstagramPending ||
                        isAuth ||
                        authInstagramInfo ||
                        !isExistData
                      }
                    >
                      {checkInstagramPending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '인증'
                      )}
                    </button>
                  </div>
                </div>
              </>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* naver/clip */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_naver_clip.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="네이버 블로그 주소 입력 (예 https://blog.naver.com/blogpeople)"
                          className="bg-[#F6F8FB]"
                          userCheck={userCheck}
                          disabled={authClipInfo || isAuth}
                          error={isAuthCheckClip}
                        />
                      </>
                    )}
                    name={`clipUrl`}
                    control={authenticationControl}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleAuthExitButton('clipUrl')
                      }}
                      disabled={isAuth}
                      type="button"
                    >
                      <img
                        className="w-[24px] h-[24px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#3A8FF2] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                      type="button"
                      onClick={handleAuthCheckClipUrl}
                      disabled={
                        checkBlogExistencePending ||
                        isAuth ||
                        authClipInfo ||
                        !isExistData
                      }
                    >
                      {checkBlogExistencePending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '인증'
                      )}
                    </button>
                  </div>
                </div>
                {(isAuth ||
                  (authClipInfo && !checkBlogExistencePending) ||
                  isAuthCheckClip) && (
                  <div className="mt-2">
                    {authClipInfo?.ogp?.['og:image'][0] && (
                      <p className="flex gap-3 items-center">
                        <span>
                          <img
                            src={authClipInfo?.ogp['og:image'][0]}
                            alt="img"
                            width={20}
                            height={20}
                            className=" rounded-[100px]"
                          />
                        </span>
                        <span className="font-bold text-[18px]">Blog</span>
                      </p>
                    )}

                    {authClipInfo?.ogp?.['og:title'][0] && (
                      <p className="font-bold text-[#2C649E]">
                        {authClipInfo?.ogp['og:title'][0]}
                      </p>
                    )}
                    {authClipInfo?.ogp?.['og:description'][0] && (
                      <p>{authClipInfo?.ogp['og:description'][0]}</p>
                    )}

                    {isAuthCheckClip && (
                      <p className="text-error text-[14px]">
                        사용할 수 없는 블로그 입니다
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
            <div className="flex flex-col gap-2 w-full">
              <>
                {/* 틱톡 */}
                <div className="flex items-center mr-2">
                  <img
                    src="assets/img/icon_tiktok.png"
                    alt="icon"
                    width={48}
                    height={48}
                  />
                </div>
                <div className="flex relative ">
                  <Controller
                    render={({ field: { onBlur, value, ...field } }) => (
                      <>
                        <TextAreaEvent
                          value={value}
                          {...field}
                          placeholder="프로필 링크 입력"
                          className="bg-[#F6F8FB]"
                          userCheck={userCheck}
                          disabled={authTiktokInfo || isAuth}
                          error={isAuthCheckTiktok}
                        />
                      </>
                    )}
                    name={`tiktokUrl`}
                    control={authenticationControl}
                  />
                  <div className="flex gap-[16px] absolute right-2 -bottom-14 top-0 items-center ">
                    <button
                      onClick={() => {
                        handleAuthExitButton('tiktokUrl')
                      }}
                      disabled={isAuth}
                      type="button"
                    >
                      <img
                        className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                        src="assets/img/icon_circle_x.png"
                        alt="x"
                      />
                    </button>
                    <button
                      className="bg-[#3A8FF2] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3] "
                      onClick={handleAuthCheckTiktokUrl}
                      disabled={
                        checkTiktokPending ||
                        isAuth ||
                        authTiktokInfo ||
                        !isExistData
                      }
                      type="button"
                    >
                      {checkTiktokPending ? (
                        <span className="loader"></span> // 로더 컴포넌트
                      ) : (
                        '인증'
                      )}
                    </button>
                  </div>
                </div>
                {(isAuth ||
                  (authTiktokInfo && !checkTiktokPending) ||
                  isAuthCheckTiktok) && (
                  <div className="flex flex-col gap-1  text-[15px]">
                    {tiktokInfo?.['title'] && (
                      <span className="font-bold">TikTok</span>
                    )}

                    {tiktokInfo?.['title'] && (
                      <p className="font-bold']} text-[#2C649E]">
                        {isAuth
                          ? tiktokInfo?.['title']
                          : authTiktokInfo?.['title']}
                      </p>
                    )}
                    {tiktokInfo?.['desc'] && (
                      <p>
                        {' '}
                        {isAuth
                          ? tiktokInfo?.['desc']
                          : authTiktokInfo?.['desc']}
                      </p>
                    )}

                    {isAuthCheckTiktok && (
                      <p className="text-error text-[14px]">
                        찾을 수 없는 주소입니다.
                      </p>
                    )}
                  </div>
                )}
              </>
            </div>
          </div>
          <div className="flex justify-start w-full pl-5">
            <ul className=" list-decimal text-[14px] text-[#5E6068]">
              <li>
                영상 업로드{' '}
                <span className="text-error font-bold">
                  인증 완료 후에는 채널 주소 변경이 불가능
                </span>
                하니, 정확한 주소를 입력해주세요!
              </li>

              <li className="mt-2">
                위 채널 중{' '}
                <span className="text-error font-bold">최소 1개의 채널</span>에{' '}
                <span className="text-error font-bold">
                  생성된 영상 30개 전부 업로드
                </span>{' '}
                되어야 합니다.
              </li>
              <li className="mt-2">
                추가 캐시는 10,000캐시입니다. 이는 업로드 한 채널의 수와
                무관합니다.
              </li>
              <li className="mt-2">관리자 확인 후 캐시 적립이 진행됩니다.</li>
              <li className="mt-2">
                추가 적립된 이벤트 캐시는 1개월간 사용이 가능합니다.
              </li>
            </ul>
          </div>
          <div
            className={classNames(
              'flex justify-center w-[335px] text-white rounded-[60px] font-bold  text-[18px] items-center  ',
              {
                'bg-[#3A8FF2]': !isAuth,
                'bg-[#D9DEE8]': isAuth || !isExistData || handleAuthCheck(),
              }
            )}
          >
            <button
              className="w-full h-[60px]"
              type="submit"
              disabled={isAuth || !isExistData || handleAuthCheck()}
            >
              영상 업로드 인증
            </button>
          </div>
        </div>
      </form>
      {isSuccess && (
        <SuccessModal
          isSuccess={isSuccess}
          setSuccess={setSuccess}
          navigate={navigate}
          refetch={refetch}
        />
      )}
      {isCheckSave && (
        <CheckSaveModal
          isCheckSave={isCheckSave}
          setCheckSave={setCheckSave}
          onSubmit={onSubmit}
          submitPending={savePending}
        />
      )}
      {isAuthSuccess && (
        <AuthSuccessModal
          refetch={refetch}
          isAuthSuccess={isAuthSuccess}
          setAuthSuccess={setAuthSuccess}
        />
      )}
    </div>
  )
}

const TextAreaEvent = (props: any) => {
  const { error, inputRef, isCheck, userCheck } = props

  const inputClass = classNames(
    'w-[343px] text-base md:w-full h-[123px]  rounded-[10px] p-[16px] bg-[#F6F8FB]',
    {
      'border border-error focus:outline-none': error,
      'border-[#D9DEE8]': !error,
      'opacity-[0.5]': isCheck,
    }
  )
  return (
    <textarea
      {...props}
      className={inputClass}
      ref={inputRef}
      onClick={userCheck}
    />
  )
}

const SuccessModal = (props: any) => {
  const { isSuccess, setSuccess, navigate, refetch } = props
  return (
    <Modal isModal={isSuccess}>
      <div className="bg-white w-[335px] h-[208px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">🎉 영상 생성 요청 완료</p>
          <div className="flex flex-col leading-[22px]">
            <p className=" text-[14px] ">
              요청하신 영상은 <br />
              이벤트 참여 순서대로, 참여자 현황에 따라
              <br /> 며칠에 걸쳐 나누어 생성됩니다. <br />
              <span className="text-main font-bold">‘사용현황’</span> 페이지에서
              확인해주세요!
            </p>
          </div>
        </div>
      </div>
      <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-[100%] h-full bg-main rounded-b-[40px] border-b border-main font-bold"
          onClick={() => {
            refetch()
            setSuccess(false)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}

const CheckSaveModal = (props: any) => {
  const { isCheckSave, setCheckSave, navigate, onSubmit, submitPending } = props
  return (
    <Modal isModal={isCheckSave}>
      <div className="bg-white w-[335px] h-[208px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">
            🎯 입력하신 블로그 링크로 <br />
            영상 생성을 요청하시겠어요?
          </p>
          <div className="flex flex-col text-[14px] leading-[22px]">
            <p>1. 영상 생성 요청은 계정당 1회만 가능합니다.</p>
            <p>2. 입력하신 블로그 링크는 변경 불가능합니다.</p>
            <p>3. 블로그에 포스트가 30개 이상 있어야 생성됩니다.</p>
          </div>
        </div>
      </div>
      <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className=" w-[50%] h-full bg-[#EEF1F7] rounded-bl-[40px] border-b text-[#5E6068] border-[#EEF1F7] font-bold"
          onClick={() => {
            setCheckSave(false)
          }}
        >
          취소
        </button>
        <button
          className="text-white w-[50%] h-full bg-main rounded-br-[40px] border-b border-main font-bold flex items-center justify-center"
          onClick={onSubmit}
        >
          {submitPending ? (
            <span className="loader"></span> // 로더 컴포넌트
          ) : (
            '영상 생성 요청'
          )}
        </button>
      </div>
    </Modal>
  )
}
const AuthSuccessModal = (props: any) => {
  const { isAuthSuccess, setAuthSuccess, refetch } = props
  return (
    <Modal isModal={isAuthSuccess}>
      <div className="bg-white w-[335px] h-[208px] p-[20px] border-t rounded-t-[40px]">
        <div className="flex flex-col items-center justify-center h-full gap-4">
          <p className="font-bold text-[18px]">🎉 영상 업로드 인증 완료</p>
          <div className="flex flex-col leading-[22px]">
            <p className=" text-[14px] ">
              인증 감사합니다! <br /> 관리자가 입력하신 채널에 방문하여 <br />
              업로드 여부를 확인하고 <br /> 조건이 충족되면 캐시 적립이
              진행됩니다.
            </p>
          </div>
        </div>
      </div>
      <div className="w-[335px] text-[14px]  h-[60px]  flex justify-center items-center">
        <button
          className="text-white w-[100%] h-full bg-main rounded-b-[40px] border-b border-main font-bold"
          onClick={() => {
            refetch()
            setAuthSuccess(false)
          }}
        >
          확인
        </button>
      </div>
    </Modal>
  )
}
