import React, { useEffect, useRef, useState } from 'react'
import Toggle from '../components/atom/Toggle'

import { Modal } from '../components/layout/Modal'
import { PaymentModal } from '../components/PaymentModal'
import useGetPaymentOrderRequests from '../hooks/query/useGetPaymentOrderRequests'
import {
  AffiliateBenefit,
  CouponBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from '../service/payment'
import useGetPlans from '../hooks/query/plans/useGetPlans'
import useGetPlansPrice from '../hooks/query/plans/useGetPlansPrice'
import {
  SubscriptionPlan,
  SubscriptionType,
  subscriptionType,
} from '../service/plans'
import { useLocation, useNavigate } from 'react-router-dom'
import useUserStore from '../store/useUserStore'
import { Button } from '../components/atom/Button'
import { usePostExperiencePlan } from '../hooks/query/usePostExperiencePlan'
import { FreePlanModal } from '../components/FreePlanModal'
import {
  getUtmLocalStorage,
  renderUserModalsByMyVideo,
  UserType,
} from '../utils/common'
import classNames from 'classnames'
import { FreeButton } from '../components/atom/FreeButton'
import useGetAutoChargeList from '../hooks/query/point/useGetAutoChargeList'
import { CashPaymentModal } from '../components/CashPaymentModal'
import { useGetMyPoint } from '../hooks/query/point/useGetMyPoint'
import { RequestPurchasePoint } from '../service/point'
import { useGetPointOrderId } from '../hooks/query/point/usetGetPoinOrderId'
import { useSaveUtm } from '../hooks/query/useSaveUtm'
import { PromotionBanner } from '../components/affiliate/PromotionBanner'
import { isMobile } from 'react-device-detect'
import {
  DefaultModal,
  NoUserModal,
  SecretModal,
} from '../components/freePlan/WelcomeBox'

export const PriceGuide = () => {
  const { data: myPointData, refetch: getMyPointRefetch } = useGetMyPoint()
  const { mutate: pointOrderIdMutate } = useGetPointOrderId()
  const { mutate: saveUtmMutate } = useSaveUtm()
  const userInfo = useUserStore((state: any) => state.userInfo)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { data: autoChargeList, isPending } = useGetAutoChargeList()
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [isUpgrade, setUpgrade] = useState<boolean>(false)
  const [monthPriceValue, setMonthPriceValue] = useState(0)
  const [isPlan, setIsPlan] = useState<boolean>(false)
  const [isCashPaymentModal, setCashPaymentModal] = useState<boolean>(false)
  const [type, isType] = useState('annual')
  const [planIdValue, setPlanId] = useState(0)
  const [isToggled, setIsToggled] = useState(false)
  const [isMonth, setIsMonth] = useState<boolean>(false)
  const [isFreePlanModal, SetFreePlanModal] = useState<boolean>(false)
  const [requestPurchasePoint, setRequestPurchasePoint] =
    useState<RequestPurchasePoint>({ orderId: '' })
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })
  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)

  const [inviteBenefit, setInviteBenefit] = useState<null | InviteBenefit>(null)
  // Plan 및 subscriptionType에 따른 가격을 가져오는 쿼리
  const { isLoading, data: plans, refetch } = useGetPlans()
  const { mutate: experiencePlanMutate } = usePostExperiencePlan()

  const {
    isLoading: priceIsLoading,
    data: price,
    refetch: priceRefetch,
  } = useGetPlansPrice(type)

  const { mutate: getPaymentOrderRequestsMutate } = useGetPaymentOrderRequests()
  const getButtonLabel = ({
    userInfo,
    plan,
    type,
  }: {
    userInfo: any
    plan: any
    type: any
  }): string => {
    if (
      userInfo?.userType === UserType.USER ||
      !userInfo ||
      userInfo?.userType === UserType.체험
    ) {
      return plan?.name === '라이트' || type === 'monthly'
        ? '사용하기'
        : '사용하기'
    }

    if (
      userInfo?.subscriptions &&
      plan.id !== userInfo?.subscriptions?.planId
    ) {
      return '문의하기'
    }

    if (
      type === subscriptionType?.ANNUAL &&
      userInfo?.subscriptions?.subscriptionType === subscriptionType?.MONTHLY
    ) {
      return '업그레이드'
    }
    if (
      type === subscriptionType.MONTHLY &&
      userInfo?.subscriptions?.subscriptionType === subscriptionType?.MONTHLY &&
      price?.benefitInfo &&
      price.benefitType === 'coupon'
    ) {
      return '사용하기'
    }

    return '문의하기'
  }

  const getPaymentOrderRequests = (
    planId: number,
    planPriceId: number,
    planPrice: SubscriptionPlan | null
  ) => {
    setPlanId(planId)
    if (!userInfo?.userInfo) {
      navigate('/sign-in')
      return
    }

    // 업그레이드 조건 클릭한 planId와 현재 사용중인 planId가 같을경우에는 상관없지
    if (
      userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY &&
      userInfo?.subscriptions?.planId === planId &&
      type === subscriptionType.ANNUAL
    ) {
      setUpgrade(true)
      setMonthPriceValue(Number(userInfo?.subscriptions?.price))
    } else if (
      // 월결제 달성 후 다시 월결제 조건
      userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY &&
      type === subscriptionType.MONTHLY &&
      userInfo?.subscriptions?.planId === planId
    ) {
    } else {
      if (
        !(
          userInfo?.userType === UserType.USER ||
          userInfo?.userType === UserType.체험
        )
      ) {
        setIsPlan(true)
        return
      }
    }

    getPaymentOrderRequestsMutate(
      { planId, planPriceId },
      {
        onSuccess: (data) => {
          setPaymentOrderRequest(data.orderRequest)
          if (data.affiliateBenefit) {
            setAffiliateBenefit(data.affiliateBenefit)
          }
          if (data?.couponBenefit) {
            setCouponBenefit(data.couponBenefit)
          }
          if (data.inviteBenefit) {
            setInviteBenefit(data.inviteBenefit)
          }
          setPaymentModal(true)
        },
        onError: (e) => {
          console.log(e)
        },
      }
    )
  }
  const paymentFreePlan = () => {
    if (!userInfo?.userInfo) {
      navigate('/sign-up')
      return
    } else if (userInfo && userInfo?.userType === UserType.체험) {
      navigate('/video-editor')
      return
    }
    const utmData = getUtmLocalStorage()
    if (utmData?.utmSource) {
      utmData.trackType = 'FREE_EXPERIENCE'
      saveUtmMutate(utmData, {
        onSuccess: () => {},
        onError: () => {},
      })
    }
    experiencePlanMutate('', {
      onSuccess: (data) => {
        navigate('/video-editor')
      },
      onError: (e) => {
        console.log(e)
        setIsPlan(true)
      },
    })
  }
  const handleToggle = () => {
    setIsToggled(!isToggled)
    setIsMonth(!isMonth)
  }

  const handleCashPaymentModal = () => {
    if (userInfo) {
      pointOrderIdMutate(undefined, {
        onSuccess: (data) => {
          setRequestPurchasePoint(data)
          setCashPaymentModal(true)
        },
        onError: () => {},
      })
    } else {
      navigate('/sign-in')
    }
  }
  useEffect(() => {
    if (isMonth) {
      isType(subscriptionType.MONTHLY)
    } else {
      isType(subscriptionType.ANNUAL)
    }
  }, [isToggled])

  useEffect(() => {
    priceRefetch()
  }, [type])

  useEffect(() => {
    if (paymentModal || isFreePlanModal) {
      document.body.classList.add('overflow-hidden') // Tailwind 유틸리티 클래스 사용
    } else {
      document.body.classList.remove('overflow-hidden')
    }
  }, [paymentModal, isFreePlanModal])

  return (
    <div className="md:mt-[60px] md:max-w-[1000px] max-w-[375px] mt-[24px] my-0 mx-auto flex flex-col items-center md:gap-[20px] gap-[40px]">
      <div className="flex w-[343px] md:w-full flex-col items-center">
        <p className="md:text-[48px] text-[32px] font-bold ">
          롱폼 플랜 가격 안내
        </p>
        {renderUserModalsByMyVideo(userInfo)}
        <p className=" leading-[21.6px] text-center text-[18px] mt-[40px]">
          합리적인 요금의 패스트컷 사용 플랜. <br />
          당신의 영상 업로드 계획과 가격 할인 등을 고려해서
          <span className="text-main font-bold"> 플랜과 사용 기간을 선택</span>
          하세요!
        </p>

        <div className="flex w-[335px] md:w-full md:h-auto h-[86px] md:items-center items-end justify-center  mt-6">
          <Toggle isToggled={isToggled} handleToggle={handleToggle} />
        </div>
      </div>
      {type === 'monthly' && price?.benefitType === 'coupon' && (
        <div>
          <button
            className="bg-main text-white md:w-[460px] w-[335px] h-[42px] rounded-[40px]"
            style={{
              background: 'linear-gradient(to right, #52EBA6, #005EE1)',
            }}
          >
            <span className="font-[800] font-[Tenada]">
              {price.benefitInfo?.couponDescription}
            </span>
          </button>
        </div>
      )}

      {type === 'annual' && price?.benefitType === 'coupon' && (
        <div>
          <button
            className="bg-main text-white md:w-[460px] w-[335px] h-[42px] rounded-[40px]"
            style={{
              background: 'linear-gradient(to right, #6095FF, #D512E7)',
            }}
          >
            <span className="font-[800] font-[Tenada]">
              {price.benefitInfo?.couponDescription}
            </span>
          </button>
        </div>
      )}
      {price?.benefitType === 'invite' && !userInfo?.alreadyBenefit && (
        <div className="w-[335px] text-[14px] md:text-base md:w-[460px] h-[42px] md:mb-10">
          <button
            className="text-white font-[800] flex items-center justify-center w-full rounded-[40px] h-full "
            style={{
              fontFamily: 'Tenada',
              background:
                'linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))',
            }}
          >
            <span className="mt-0.5">
              {' '}
              지금 바로 첫 결제하시면,10% 추가 캐시 적립!
            </span>
          </button>
        </div>
      )}

      {userInfo?.affiliate.point && (
        <PromotionBanner
          daysLeft={userInfo?.affiliate?.point?.daysLeft}
          hoursLeft={userInfo?.affiliate?.point?.hoursLeft}
          affiliateImage={userInfo?.affiliate?.point?.affiliateImage}
          affiliateDescription={
            userInfo?.affiliate?.point?.affiliateDescription
          }
        />
      )}
      <div className="flex flex-col md:w-[1000px] md:flex-row gap-[8px]">
        {plans?.map((plan, i) => {
          return (
            <div
              className={classNames(
                'border rounded-[20px] border-[#EEF1F7] shadow-main flex flex-col md:w-[328px] w-[343px]  gap-[32px] px-[24px] py-[40px] ',
                {
                  'border-[4px] border-main':
                    !userInfo ||
                    userInfo?.userType === UserType.USER ||
                    userInfo?.userType === UserType.체험
                      ? plan.name === '프리미엄'
                      : plan.id === userInfo?.subscriptions.planId,
                }
              )}
            >
              <div className="flex flex-col items-center">
                <p className="font-bold text-main text-[24px] flex gap-[2px]">
                  {plan?.name} 플랜
                  {plan.name === '프리미엄' && (
                    <div>
                      <div className="h-[4px]"></div>
                      <div className="text-[14px] font-bold bg-error text-[#FFFFFF] rounded-[4px]  h-[17px] flex items-center p-[2px]">
                        <div>BEST</div>
                      </div>
                    </div>
                  )}
                </p>
                {price?.benefitType === 'invite' && (
                  <p className="text-error font-bold">
                    {' '}
                    첫 결제 +{' '}
                    {`${price.prices[
                      i
                    ].planPrice[0].bonusPoint?.toLocaleString()}`}{' '}
                    캐시 보너스
                  </p>
                )}
                {userInfo?.usage?.isDone &&
                type === 'annual' &&
                userInfo?.userType === UserType.체험 ? (
                  <p
                    className="font-bold h-[19px]"
                    style={{
                      background: 'linear-gradient(90deg, #6095FF, #D512E7)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    {' '}
                    {plan?.name !== '라이트' && '무료 체험 완료 50% 할인'}
                  </p>
                ) : (
                  <></>
                )}

                {!!price?.prices?.length &&
                price.prices[i].planPrice[0].subscriptionType === 'monthly' ? (
                  <>
                    <p>
                      <span className="font-bold text-[32px]">
                        ₩
                        {!!price?.prices?.length &&
                          price.prices[i]?.planPrice[0].price.toLocaleString()}
                      </span>{' '}
                      <span className="font-bold "> /월(VAT 별도)</span>
                    </p>
                  </>
                ) : (
                  <>
                    {/* annual 가격 폼 */}
                    <p className="flex flex-col items-center">
                      {/* <span className="font-bold text-[18px] text-[#2E343D]">
                        <s>
                          {!!price?.length &&
                            price[
                              i
                            ]?.planPrice[0]?.originPrice.toLocaleString()}
                          원
                        </s>
                      </span> */}
                      {/* <span className="font-bold text-[18px] text-error">
                        100명 한정 가격
                      </span> */}
                      {userInfo?.affiliate?.plan?.length > 0 && (
                        <>
                          <p className="text-error font-bold text-[18px]">
                            {userInfo?.affiliate?.plan[0]?.name}
                          </p>
                        </>
                      )}
                      <p>
                        {' '}
                        <s className="font-bold text-[#8F929B] text-[24px]">
                          ₩
                          {!!price?.prices?.length &&
                            price.prices[
                              i
                            ]?.planPrice[0].monthlyPrice.toLocaleString()}
                          {` `}
                        </s>{' '}
                        <s className="text-[16px] text-[#8F929B] font-bold">
                          /월
                        </s>
                      </p>

                      <p>
                        <span className="font-bold text-[32px]">
                          ₩
                          {!!price?.prices?.length &&
                            Number(
                              price.prices[i]?.planPrice[0].descriptionPrice
                            ).toLocaleString()}
                        </span>
                        <span className="font-bold"> /월(VAT 별도)</span>
                      </p>
                    </p>
                    <span className="text-[18px] text-[#8F929B]">
                      ₩
                      {!!price?.prices?.length &&
                        Number(
                          price.prices[i]?.planPrice[0].price
                        ).toLocaleString()}
                      /1년
                    </span>
                  </>
                )}
                {type === 'annual' && (
                  <div
                    className={classNames(
                      'flex justify-center h-[48px] mt-2 cursor-pointer'
                    )}
                    onClick={() => {
                      navigate('/coupon-pack')
                    }}
                  >
                    {' '}
                    {plan.name !== '라이트' &&
                      (!userInfo ||
                        userInfo?.userType === UserType.체험 ||
                        userInfo?.userType === UserType.USER) && (
                        <img
                          src="assets/img/coupon/50percenty.png"
                          alt=""
                          className="w-[119px] h-[48px]"
                        />
                      )}
                  </div>
                )}
              </div>

              <div>
                <Button
                  className="bg-main w-full h-[48px] border-none rounded-[48px] text-white font-bold disabled:opacity-[0.5]"
                  onClick={() => {
                    getPaymentOrderRequests(
                      plan.id,
                      !!price?.prices?.length
                        ? price.prices[i]?.planPrice[0].id
                        : 0,
                      !!price?.prices?.length
                        ? price.prices[i]?.planPrice[0]
                        : null
                    )
                  }}
                >
                  {getButtonLabel({ userInfo, plan, type })}
                </Button>
              </div>
              <div className="flex flex-col ">
                <div className="plan flex flex-col md:h-[200px] h-auto ">
                  <p className="font-bold text-[18px] flex items-center gap-[5px] mb-[24px]">
                    <img
                      src="assets/img/check_circle.png"
                      alt="check_circle"
                      width={24}
                      height={24}
                    />
                    {type === 'annual' ? <>12개월 사용</> : <>1개월 사용</>}
                  </p>

                  {plan?.planDetail.map((el, index) => {
                    return (
                      <>
                        {el.type === 'feature' && (
                          <p className="font-bold text-[18px] flex items-center gap-[5px] mb-[24px]">
                            <img
                              src={
                                el.highlight
                                  ? 'assets/img/check_circle_red.png'
                                  : 'assets/img/check_circle.png'
                              }
                              alt="check_circle"
                              width={24}
                              height={24}
                            />
                            <span className="relative">
                              <span>{el.name}</span>
                              <span
                                className={
                                  el.highlight
                                    ? 'absolute left-0 bottom-[2px] w-full h-[10px] bg-[#FD6767] opacity-[0.4]'
                                    : ''
                                }
                                style={{ zIndex: -1 }}
                              ></span>
                            </span>
                          </p>
                        )}
                      </>
                    )
                  })}
                </div>
                <hr className="mt-[10px] mb-[30px] h-auto" />
                <div className="flex flex-col">
                  {plan?.planDetail.map((el, index) => {
                    return (
                      <>
                        {el.type === 'function' && (
                          <p
                            className={`font-bold text-[18px] flex items-center gap-[5px] mb-[24px] `}
                          >
                            <img
                              src={
                                el.highlight
                                  ? 'assets/img/check_circle_red.png'
                                  : 'assets/img/check_circle.png'
                              }
                              alt="check_circle"
                              width={24}
                              height={24}
                            />
                            <span className="relative">
                              <span>{el.name}</span>
                              <span
                                className={
                                  el.highlight
                                    ? 'absolute left-0 bottom-[2px] w-full h-[10px] bg-[#FD6767] opacity-[0.4]'
                                    : ''
                                }
                                style={{ zIndex: -1 }}
                              ></span>
                            </span>
                          </p>
                        )}
                      </>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="w-full md:text-[18px] text-[14px] pb-[20px] mt-[10px] md:px-0 px-5">
        <ul className="list-disc pl-5 md:pl-28">
          <li className="mb-[8px]">
            프로, 프리미엄 플랜의 경우, 유튜브 권한 설정이 완료된 상태여야
            유튜브 자동 업로드가 가능합니다.
          </li>
          <li className="mb-[4px] leading-[28.8px]">
            플랜별 영상 생성 개수는 사용자가 ‘생성 요청한 영상’의 개수를
            기준으로 카운팅 합니다.
          </li>
          <li className="mb-[4px] leading-[28.8px]">
            영상 생성은 요청 후 24시간 내(최대 72시간)에 완료됩니다
          </li>
          <li className="mb-[4px] leading-[28.8px]">
            프로,프리미엄 플랜은 1일 최대 3개까지 오전 10시, 오후 2시, 오후
            6시에 업로드 예약 됩니다.
          </li>
          <li className="mb-[4px] leading-[28.8px]">
            생성과 업로드 일정 등은 서비스 현황에 따라 별도의 공지 없이 변동될
            수 있습니다.
          </li>
        </ul>
      </div>
      <div className="flex w-[343px] md:w-full flex-col items-center mt-10">
        <p className="md:text-[48px] text-[32px] font-bold">숏폼 가격 안내</p>
        {price?.benefitType === 'invite' && !userInfo?.alreadyBenefit && (
          <div className="w-[335px] text-[14px] md:text-base md:w-[460px] h-[42px] mt-5">
            <button
              className="text-white font-[800] flex items-center justify-center w-full rounded-[40px] h-full "
              style={{
                fontFamily: 'Tenada',
                background:
                  'linear-gradient(to right, rgba(96, 149, 255, 1),rgba(213, 18, 231, 1))',
              }}
            >
              <span className="mt-0.5">
                {' '}
                지금 바로 첫 결제하시면,10% 추가 캐시 적립!
              </span>
            </button>
          </div>
        )}
        {userInfo?.affiliate.point && (
          <PromotionBanner
            daysLeft={userInfo?.affiliate?.point?.daysLeft}
            hoursLeft={userInfo?.affiliate?.point?.hoursLeft}
            affiliateImage={userInfo?.affiliate?.point?.affiliateImage}
            affiliateDescription={
              userInfo?.affiliate?.point?.affiliateDescription
            }
            className="mt-5"
          />
        )}
        <div className="bg-[#F6F8FB] md:px-[32px] md:py-10 p-5 flex flex-col rounded-[20px] mt-10 md:w-[936px]">
          <div className="font-bold md:text-[24px]">
            <p>
              <span className="text-main">숏폼은 패스트컷 캐시를 사용</span>해서
              생성할 수 있어요.{!isMobile && <br />} 사용하실 효과들을 고려해서
              충전 단위를 선택하고,
              {` `}
              <span className="text-main">보너스 캐시</span>까지 얻어보세요!
            </p>
          </div>
          <div className="bg-[#D2F8F8] px-4 py-4 flex md:gap-4 gap-2 rounded-[10px] mt-4 md:items-center">
            <div className="md:w-[39px] md:h-[46px] w-[25px] h-[28px]">
              <img
                src="assets/img/cash_price_guide.png"
                alt="x"
                width={39}
                height={46}
              />
            </div>
            <p className="md:text-base text-[14px] md:w-auto w-[231px]">
              숏폼 효과들 중에서 캐시 아이콘이 붙어있는 항목을 사용하려면 캐시가
              필요해요. <br />
              항목별로 몇 캐시인지는 숏폼 만들기 화면에서 확인하세요!{' '}
            </p>
          </div>
          <div className="bg-white mt-4 p-5 md:p-7 rounded-[10px] text-[16px] md:text-[18px]">
            {autoChargeList?.chargeMap?.total?.map((el, index) => (
              <>
                <div className="flex gap-[8px] items-center justify-between">
                  <p className="font-bold flex items-center">
                    {Number(el.chargeAmount).toLocaleString()}캐시
                    {Number(el.chargeAmount) === 100000 && (
                      <span className="bg-main text-white rounded-[4px] md:px-3 px-1 py-0.5 md:py-[4px] md:text-[18px] text-[10px] font-bold md:ml-2 ml-1  ">
                        인기
                      </span>
                    )}
                  </p>
                  {Number(el.bonusRate) > 0 && (
                    <div className="flex flex-col items-end text-[12px] md:text-[18px]">
                      <p className="font-bold ">
                        {' '}
                        + {`${el.bonus.toLocaleString()}`} 캐시 보너스
                      </p>
                      <p className="md:text-[14px] text-[12px]">
                        {autoChargeList.benefitInfo?.affiliateDescription &&
                          '제휴 혜택'}
                        {autoChargeList.benefitInfo?.inviteCode && '초대 코드'}
                        {autoChargeList.benefitType === 'purchase' &&
                          '충전 혜택'}
                        {` `}
                        <span
                          className={classNames(
                            'font-bold px-1 rounded-[4px]',
                            {
                              'text-error bg-[#EEF1F7]':
                                Number(el.bonusRate) <= 9,
                              'bg-error text-white': Number(el.bonusRate) > 9,
                            }
                          )}
                        >
                          {`${Number(el.bonusRate).toFixed(0)}%`}
                        </span>
                      </p>
                    </div>
                  )}
                </div>
                {index !== autoChargeList.chargeMap.total.length - 1 && (
                  <hr className="h-[1px] bg-[#EEF1F7] my-[20px]" />
                )}
              </>
            ))}
          </div>
          <div className="flex mt-4" onClick={handleCashPaymentModal}>
            <Button className="w-full bg-main py-[19px] text-white rounded-[40px] font-bold text-[18px]">
              캐시 충전하기
            </Button>
          </div>
        </div>
      </div>
      <div className="md:w-[1000px] w-[335px] md:h-[208px] p-5 md:px-[32px] md:py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-[#735CFF] rounded-[20px]">
        <div className="flex flex-col items-center">
          <p className="font-bold md:text-[24px] text-[16px]">커스텀 솔루션</p>
          <p className="font-[800] text-[#735CFF]  md:text-[32px] text-[20px]">
            고객 맞춤 플랜
          </p>
        </div>
        <div className="md:text-[18px] text-[14px] font-bold flex flex-col gap-[16px] mt-[32px] md:mt-0">
          <p className="flex items-center gap-2 md:text-[18px] text-[14px]">
            {' '}
            <img
              src="assets/img/check_circle_purple.png"
              alt="check"
              width={24}
              height={24}
            />
            브랜드, 플랫폼, 에이전시 고객을 위한 맞춤형 솔루션
          </p>
          <p className="flex items-center gap-2 md:text-[18px] text-[14px]">
            {' '}
            <img
              src="assets/img/check_circle_purple.png"
              alt="check"
              width={24}
              height={24}
            />
            고객의 필요에 따라 사이트 고객 맞춤 작업을 지원
          </p>
          <p className="flex items-center gap-2 md:text-[18px] text-[14px]">
            {' '}
            <img
              src="assets/img/check_circle_purple.png"
              alt="check"
              width={24}
              height={24}
            />
            <a
              href="https://angelleague.notion.site/AI-115ba20e97f3803d91cdc2c647149c3e"
              target="_blank"
              rel="noreferrer"
              className="text-[#735CFF] underline"
            >{`고객 맞춤 플랜 상세 내용 확인 >>`}</a>
          </p>
        </div>
        <div className="flex flex-col gap-[8px] font-bold text-[18px] mt-[32px] md:mt-0">
          <a
            href="http://pf.kakao.com/_CFxfHxj/chat"
            target="_blank"
            rel="noreferrer"
            className="w-full"
          >
            <button
              className="w-[264px] h-[60px]  border-[#735CFF] border-[2px] rounded-[40px] bg-[#735CFF] text-white text-[18px]"
              onClick={() => {}}
            >
              카톡으로 문의하기
            </button>
          </a>
        </div>
      </div>

      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          inviteBenefit={inviteBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          couponBenefit={couponBenefit}
          userInfo={userInfo?.userInfo}
          planId={planIdValue}
          type={type}
          upgrade={isUpgrade}
          monthPrice={monthPriceValue}
        />
      )}

      {isCashPaymentModal && (
        <CashPaymentModal
          setCashPaymentModal={setCashPaymentModal}
          userInfo={userInfo?.userInfo}
          affiliatePoint={userInfo?.affiliate?.point}
          userCurrentPoint={myPointData.totalPoint}
          requestPurchasePoint={requestPurchasePoint}
        />
      )}

      {isPlan && (
        <Modal isModal={isPlan}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                이미 사용중인 플랜이 있어요!
              </p>
              <div>
                <p className="">
                  사용중인 플랜의 기한이 지난 후<br /> 새로운 플랜을
                  결제해주시거나 <br />
                  패스트컷에 따로 문의해주세요!
                </p>
              </div>
            </div>
          </div>
          <div className="w-[400px] bg-main h-[60px] border-b border-main rounded-b-[20px] flex justify-center items-center ">
            <button
              className="text-white w-[400px] font-bold"
              onClick={() => {
                setIsPlan(false)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}

      {isFreePlanModal && (
        <FreePlanModal
          isFreePlanModal={isFreePlanModal}
          setFreePlanModal={SetFreePlanModal}
        />
      )}
    </div>
  )
}
