import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { checkBlogExists, checkInstagramExists } from '../../../service/event'

export const useCheckInstagramExistence = () => {
  return useMutation({
    mutationKey: ['check-instagram-existence'],
    mutationFn: checkInstagramExists,
  })
}
