import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { checkBlogExists } from '../../../service/event'

export const useCheckBlogExistence = () => {
  return useMutation({
    mutationKey: ['check-blog-existence'],
    mutationFn: checkBlogExists,
  })
}
