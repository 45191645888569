import React, {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { Button } from './atom/Button'
import { thumbnailOptions, ThumbnailOptionType } from '../dummy/data'
import { Controller, useForm } from 'react-hook-form'
import { Input } from './atom/Input'
import { Modal } from './layout/Modal'
import {
  getLocalStorage,
  LOCAL_THUMBNAIL_INFO,
  removeLocalStorage,
  saveLocalStorage,
  THUMBNAILINFO_LIST,
} from '../utils/common'
import classNames from 'classnames'
interface ModalProps {
  editThumbnail: boolean
  setEditThumbnail: Dispatch<SetStateAction<boolean>>
  userInfo: any
  thumbnailType: ThumbnailOptionType
  setThumbnailInfo: Dispatch<SetStateAction<ThumbnailInfo>>
  thumbnailInfo: ThumbnailInfo
}

export interface ThumbnailInfo {
  channelName?: string
  title1?: string
  title2?: string
  title3?: string
  thumbnailFile?: any
}
export const Thumbnail = ({
  children,
  editThumbnail,
  setEditThumbnail,
  userInfo,
  thumbnailType,
  setThumbnailInfo,
  thumbnailInfo,
}: PropsWithChildren<ModalProps>) => {
  removeLocalStorage(THUMBNAILINFO_LIST)
  const localT = getLocalStorage(LOCAL_THUMBNAIL_INFO)

  //채널이름 체크박스 state
  const [isCheck, setCheck] = useState<boolean>(false)
  const [isTitleCheck, setTitleCheck] = useState<boolean>(false)
  const [imgSrc, setImgSrc] = useState<any>(
    'https://pub.angelleague.io/fast_cut/thumbnail/thumbnail00.png'
  )
  const [essentialCheck, setEssentialCheck] = useState<boolean>(false)
  const [emptyChannelName, setEmptyChannelName] =
    useState<string>('내 채널 이름이 들어가요')

  //! 썸네일 관련 hook
  const {
    register: thumbnailRegister,
    control: thumbnailControl,
    handleSubmit: thumbnailHandleSubmit,
    reset: thumbnailReset,
    trigger: thumbnailTrigger,
    setError: thumbnailSetError,
    watch: thumbnailWatch,
    setValue: thumbnailSetValue,
    formState: { errors: thumbnailErrors },
  } = useForm({
    defaultValues: {
      channelName: '',
      title1: '',
      title2: '',
      title3: '',
      thumbnailFile: [],
    },
  })

  const thumbnailOnSubmit = async (data: any) => {
    thumbnailOptions[thumbnailType.name].titleList.forEach((el, index) => {
      el.title.titleContent = data[`title${index + 1}`]
    })

    saveLocalStorage(LOCAL_THUMBNAIL_INFO, {
      [thumbnailType.name]: data,
      isCheck,
      isTitleCheck,
    })
    setThumbnailInfo(data)
    setEditThumbnail(false)
  }
  const THUMBNAIL_PATTERN = /^[^\uD800-\uDFFF]*$/
  const CHANNEL_NAME_PATTERN = /^[^\uD800-\uDFFF&]*$/
  useEffect(() => {
    if (localT && localT[thumbnailType?.name]) {
      const tInfo = localT[thumbnailType?.name]
      const titleCheckByStorage = localT['isTitleCheck']
      const channelNameCheckByStorage = localT['isCheck']
      setTitleCheck(titleCheckByStorage)
      setCheck(channelNameCheckByStorage)

      console.log(titleCheckByStorage, channelNameCheckByStorage)
      if (!tInfo?.channelName) {
        setCheck(true)
        setEmptyChannelName('')
      }
      thumbnailSetValue('channelName', tInfo?.channelName)
      thumbnailSetValue('title1', tInfo?.title1)
      thumbnailSetValue('title2', tInfo?.title2)
      thumbnailSetValue('title3', tInfo?.title3)
      const thumbnailFile = thumbnailInfo?.thumbnailFile || []
      thumbnailSetValue(`thumbnailFile`, thumbnailFile)
      if (thumbnailFile.length) {
        setImgSrc(thumbnailFile[0])
      }
    }

    if (userInfo) {
      if (userInfo?.userInfo?.youtubeAccount) {
        thumbnailSetValue(
          'channelName',
          userInfo?.userInfo?.youtubeAccount.youtubeChannelName
        )
      }
    }
  }, [editThumbnail])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const selectedFile = Array.from(event.target.files)

      // 업로드한 파일을 products[index].files에 추가
      // @ts-ignore
      thumbnailSetValue(`thumbnailFile`, selectedFile)
      setImgSrc(selectedFile[0])
    }
  }
  const handleTitleCheckBox = (flag: boolean) => {
    if (flag) {
      thumbnailSetValue('title1', ' ')
      thumbnailSetValue('title2', ' ')
      thumbnailSetValue('title3', ' ')
    } else {
      thumbnailSetValue('title1', '')
      thumbnailSetValue('title2', '')
      thumbnailSetValue('title3', '')
    }
  }
  const handleRemoveImage = () => {
    // 이미지 제거 처리
    thumbnailSetValue('thumbnailFile', []) // thumbnailFile을 빈 배열로 설정
    setImgSrc('https://pub.angelleague.io/fast_cut/thumbnail/thumbnail00.png') // 기본 이미지로 재설정
  }

  // @ts-ignore
  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center  z-50">
      <form
        onSubmit={thumbnailHandleSubmit(thumbnailOnSubmit, (err) => {
          console.log(err)
          setEssentialCheck(true)
        })}
        className="h-[90%] overflow-y-scroll"
      >
        <div className="bg-white w-[343px] md:w-[816px]  p-[40px] border-t rounded-[20px] ">
          <div>
            <p className="flex justify-end">
              <button
                onClick={() => {
                  setEditThumbnail(false)
                }}
              >
                <img
                  src="assets/img/Icon_x.png"
                  alt="icon_x"
                  height={40}
                  width={40}
                />
              </button>
            </p>
          </div>
          <div className="flex flex-col items-center gap-[4px]">
            <p className="font-bold text-[24px]">
              썸네일 {thumbnailType.name.split('_')[1]}
            </p>
            <p className="text-main text-[14px]">
              내용이 {thumbnailType.titleList.length}줄 들어가는 썸네일 스타일을
              선택하셨어요
            </p>
            <p className="text-[#4360EC] text-[14px]">
              적용된 배경 이미지는 예시입니다. 실제 썸네일에는 선택하신
              상품페이지의 첫 번째 상품 이미지가 적용됩니다.
            </p>
            <div className="flex mt-[16px] relative">
              <div className="flex relative w-[736px] h-[414px]">
                <div
                  style={{
                    width: '736px',
                    height: '100%',
                    overflow: 'hidden',
                    position: 'relative',
                  }}
                >
                  <img
                    style={{
                      width: '736px',
                      height: 'auto',
                      position: 'absolute',
                      objectFit: 'cover',
                    }}
                    src={
                      typeof imgSrc === 'string'
                        ? imgSrc
                        : URL.createObjectURL(imgSrc)
                    }
                    alt="thumbnail"
                  />
                </div>
                {/* opacity 배경 */}
                {thumbnailType.opacity && (
                  <div
                    className={` op absolute w-[736px] h-[414px] bg-black opacity-[${thumbnailType.opacity}]`}
                  ></div>
                )}

                {/* 그라데이션 배경 */}
                {thumbnailType.bg_gradient && (
                  <div
                    className={` bg_gra absolute w-[736px] h-[414px] bg-black`}
                    style={{
                      background: thumbnailType.bg_gradient,
                    }}
                  ></div>
                )}

                {/* inner border */}
                {thumbnailType.border && (
                  <div className="absolute w-[736px] h-[414px] flex items-center justify-center">
                    <div
                      className={`absolute w-[713px] h-[391px] border`}
                      style={{
                        borderWidth: `${thumbnailType.border.weight}`,
                        borderColor: `${thumbnailType.border.color}`,
                      }}
                    ></div>
                  </div>
                )}

                {/* out border */}
                {thumbnailType.outline_border && (
                  <div
                    className="absolute w-[736px] h-[414px] flex items-center justify-center"
                    style={{
                      borderWidth: `${thumbnailType.outline_border.weight}`,
                      borderColor: `${thumbnailType.outline_border.color}`,
                    }}
                  ></div>
                )}

                {/* 좌상단 채널 이름 */}
                <div
                  className="channel_name absolute top-0  w-[410.5px] h-[40.25px] flex items-center pl-[12.08px]"
                  style={{
                    background:
                      'linear-gradient(to right, rgba(35, 35, 35, 1),rgba(167, 167, 167, 0))',
                  }}
                >
                  <p className="font-bold text-[23px] text-[#FFFFFF] ">
                    {thumbnailWatch('channelName')
                      ? thumbnailWatch('channelName')
                      : emptyChannelName}
                  </p>
                </div>

                {thumbnailType.titleList.map((el, index) => {
                  return (
                    <div
                      className={`absolute w-full h-[80px] flex items-center px-[18px] align-middle `}
                      style={{
                        top: `${el.position}`,
                        textAlign: `${el.title.align ? 'left' : 'center'}`,
                      }}
                    >
                      {/*  */}
                      {thumbnailType.blank_box && (
                        <p
                          className=" absolute h-[45px] bg-[#414141] text-[transparent] z-0"
                          style={{
                            top: `40%`,
                            left: '1%',
                            fontSize: `${el.title.size}`,
                            fontWeight: `${el.title.weight}`,
                            fontFamily: `${el.title.font}`,
                            letterSpacing: `-1px`,
                          }}
                        >
                          {thumbnailWatch(el.title.titleName)
                            ? thumbnailWatch(el.title.titleName)
                            : el.title.titleContent}
                        </p>
                      )}
                      <p
                        className={`${el.title.border} w-full z-10`}
                        style={{
                          color: `${el.title.color}`,
                          fontSize: `${el.title.size}`,
                          fontWeight: `${el.title.weight}`,
                          fontFamily: `${el.title.font}`,
                          textShadow: `${el.title.shadow}`,
                          letterSpacing: `${el.title.letter}`,
                          backgroundColor: `${el.title.bg}`,
                          padding: `${el.title.padding}`,
                          background: `${el.title.gradient?.background}`,
                          WebkitBackgroundClip: `${el.title.gradient?.text}`,
                          WebkitTextStroke: `${el.title.stroke}`,
                          height: `${el.title.height}`,
                        }}
                      >
                        {thumbnailWatch(el.title.titleName)
                          ? thumbnailWatch(el.title.titleName)
                          : el.title.titleContent}
                      </p>
                    </div>
                  )
                })}
              </div>
              {/* 이미지 */}
            </div>
          </div>

          <div className="flex flex-col gap-[4px] mt-[32px]">
            <p className="font-bold text-[24px]">썸네일 이미지 업로드(선택)</p>
            <p className="text-main text-[14px]">
              이미지를 따로 업로드 하지 않으면, 해당 상품의 첫 번째 이미지가
              자동으로 사용됩니다.
            </p>
            <div className="mt-[16px]">
              <div className="flex space-x-3 h-[174px]">
                <div className="flex relative w-[308px] h-[174px] bg-[#EEF1F7]">
                  {thumbnailWatch('thumbnailFile', []).map(
                    (file, fileIndex) => (
                      <div
                        style={{
                          width: '308px',
                          height: '100%',
                          overflow: 'hidden',
                          position: 'relative',
                        }}
                      >
                        <img
                          style={{
                            width: '308px',
                            height: 'auto',
                            position: 'absolute',
                            objectFit: 'cover',
                          }}
                          src={URL.createObjectURL(file)}
                          alt="thumbnail"
                        />
                        <button
                          onClick={handleRemoveImage}
                          className="absolute top-2 right-2"
                          style={{ cursor: 'pointer' }}
                        >
                          <img
                            className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                            src="assets/img/icon_circle_x_black.png"
                            alt="x"
                          />
                        </button>
                      </div>
                    )
                  )}
                </div>
                <div className="flex flex-col justify-end h-full">
                  <button
                    onClick={(event) => {
                      event.preventDefault() // 기본 form 제출 동작을 방지
                      document.getElementById(`file-input`)?.click() // 파일 선택 창 열기
                    }}
                    className="flex items-center space-x-1 px-3 py-2 text-white rounded-full bg-[#6B8ACB] text-[14px] font-bold mb-2"
                  >
                    <img src="assets/img/icon_image.png" width="17px" />
                    <span>파일 업로드</span>
                  </button>
                  <input
                    id={`file-input`}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    className="hidden"
                    onChange={(e) => handleFileChange(e)}
                  />
                  <p className="text-[#8F929B] text-[16px] text-sm text-left mb-1">
                    권장 사이즈 1280x720
                  </p>
                  <p className="text-[#8F929B] text-[16px] text-sm text-left">
                    최대 2MB | jpg, jpeg, png
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-[4px] mt-[32px]">
            <p className="font-bold text-[24px]">썸네일 내용 입력(필수)</p>
            <p className="text-main text-[14px]">매력적인 내용을 정해주세요!</p>
            <div className="flex items-center gap-[6px] mt-[8px] text-[#8F929B]">
              <input
                type="checkbox"
                className="w-[20px] h-[20px] border border-[#8F929B] "
                checked={isTitleCheck}
                onClick={() => {
                  setTitleCheck(!isTitleCheck)
                  handleTitleCheckBox(!isTitleCheck)
                }}
              />
              <p style={{ fontWeight: 500 }} className="text-error">
                썸네일에 텍스트 넣지 않기
              </p>
            </div>
            {thumbnailType.titleList.map((el, index) => {
              return (
                <>
                  <div className="mt-[16px]">
                    <Controller
                      key={el.title.titleName}
                      render={({ field }) => (
                        <>
                          <Input
                            {...field}
                            error={
                              field.value.length > el.title.maxLength ||
                              !THUMBNAIL_PATTERN.test(field.value)
                                ? true
                                : false
                            }
                            disabled={isTitleCheck}
                            placeholder={el.title.placeholder}
                            maxLength={el.title.maxLength}
                          />
                          <div
                            className={`flex gap-[4px] mt-[8px] ${
                              (field.value.length > el.title.maxLength ||
                                !THUMBNAIL_PATTERN.test(field.value)) &&
                              'text-error'
                            }`}
                          >
                            <span
                              className={classNames('', {
                                'text-[#D4D9DF]': isTitleCheck,
                              })}
                            >
                              {field.value.length}/{el.title.maxLength}
                            </span>
                            <span
                              className={classNames('', {
                                'text-[#D4D9DF]': isTitleCheck,
                              })}
                            >{`이모지는 허용되지 않습니다.`}</span>
                          </div>
                        </>
                      )}
                      name={el.title.titleName}
                      control={thumbnailControl}
                      rules={{
                        required: {
                          value:
                            thumbnailWatch('title1') ||
                            thumbnailWatch('title2') ||
                            thumbnailWatch('title3')
                              ? false
                              : true,
                          message: '썸네일 내용은 필수입니다!',
                        },
                        maxLength: {
                          value: el.title.maxLength,
                          message: `썸네일 내용의 길이가 ${el.title.maxLength}자를 넘습니다.`,
                        },
                        validate:
                          thumbnailWatch(el.title.titleName) !== ''
                            ? (value) => {
                                return (
                                  THUMBNAIL_PATTERN.test(value) ||
                                  '이모지는 허용되지 않습니다.'
                                )
                              }
                            : undefined,
                      }}
                    />
                  </div>
                </>
              )
            })}
          </div>
          <div className="flex flex-col gap-[4px] mt-[32px]">
            <p className="font-bold text-[24px]">채널 이름 입력(선택)</p>
            <p className="text-main text-[14px]">
              썸네일 왼쪽 상단에 들어갈 유튜브 채널 이름을 입력해주세요
            </p>
            <div className="mt-[16px]">
              <Controller
                key={`channelName`}
                render={({ field }) => (
                  <>
                    <Input
                      {...field}
                      error={
                        field.value.length > 20 ||
                        !CHANNEL_NAME_PATTERN.test(field.value)
                          ? true
                          : false
                      }
                      isCheck={isCheck}
                      placeholder="운영하시는 유튜브 채널 이름을 입력해주세요 (20자 이내)"
                      maxLength={20}
                      disabled={isCheck}
                    />
                    <div
                      className={`flex gap-[4px] mt-[8px] ${
                        (field.value.length > 20 ||
                          !CHANNEL_NAME_PATTERN.test(field.value)) &&
                        'text-error'
                      }`}
                    >
                      <span>{field.value.length}/20</span>
                      <span>{`이모지와 &는 허용되지 않습니다.`}</span>
                    </div>
                    {/* 채널이름 미설정 체크박스  */}
                    <div className="flex items-center gap-[6px] mt-[8px] text-[#8F929B]">
                      <input
                        type="checkbox"
                        className="w-[20px] h-[20px] border border-[#8F929B]"
                        checked={isCheck}
                        onClick={() => {
                          thumbnailSetValue('channelName', '')
                          setEmptyChannelName('')
                          setCheck((prev) => !prev)
                        }}
                      />
                      <p style={{ fontWeight: 500 }}>채널 이름 넣지 않기</p>
                    </div>
                  </>
                )}
                name={`channelName`}
                control={thumbnailControl}
                rules={{
                  required: {
                    value: !isCheck,
                    message: '채널이름은 필수입니다!',
                  },
                  maxLength: {
                    value: 20,
                    message: '채널이름 내용의 길이가 20자를 넘습니다.',
                  },
                  validate:
                    thumbnailWatch(`channelName`) !== ''
                      ? (value) => {
                          return (
                            CHANNEL_NAME_PATTERN.test(value) ||
                            '이모지와 &는 허용되지 않습니다.'
                          )
                        }
                      : undefined,
                }}
              />
            </div>
          </div>
          <div className="mt-[32px]">
            <Button className="bg-main flex items-center justify-center w-full h-[60px] rounded-[40px]">
              <span className="text-[#FFFFFF] font-bold">저장</span>
            </Button>
          </div>
        </div>
      </form>
      {essentialCheck && (
        <Modal isModal={essentialCheck}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">
                🙌 필수 정보를 입력해주세요!
              </p>
              <p className="text-[14px]">
                내용이 입력되지 않아 저장할 수 없어요 .
              </p>
            </div>
          </div>
          <div className="w-[400px] bg-main h-[60px] border-b border-main flex   rounded-b-[20px] justify-center items-center">
            <Button
              className="text-white w-[400px] h-full  rounded-b-[20px]"
              onClick={() => {
                setEssentialCheck(false)
              }}
            >
              확인
            </Button>
          </div>
        </Modal>
      )}
    </div>
  )
}
