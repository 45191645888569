import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'

import { authChannel, saveBlog } from '../../../service/event'

function useAuthChannel() {
  return useMutation({
    mutationKey: ['auth-channel'],
    mutationFn: authChannel,
  })
}

export default useAuthChannel
