import { UseQueryResult, useMutation, useQuery } from '@tanstack/react-query'

import { saveBlog } from '../../../service/event'

function useSaveBlog() {
  return useMutation({
    mutationKey: ['save-blog-sns'],
    mutationFn: saveBlog,
  })
}

export default useSaveBlog
