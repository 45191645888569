import React from 'react'
import { FreeButton } from '../atom/FreeButton'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'

export const WelcomeBox = ({ props }: any) => {
  return (
    <div className="md:w-[816px] md: mt-[40px] px-[32px] py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-href rounded-[20px]">
      <div className="flex flex-col ">
        <p className="text-href font-bold text-[24px] text-left">
          지금 회원가입 하면
        </p>
        <p className="font-bold text-[32px] text-left">
          프리미엄 플랜 기능
          <br /> 무료체험 가능!
        </p>
      </div>
      <div className="text-[18px] font-bold flex flex-col gap-[16px] mt-[40px] md:mt-0">
        <p className="flex items-center gap-[4px]">
          {' '}
          <img
            src="assets/img/check_circle_blue.png"
            alt="check"
            width={24}
            height={24}
          />
          최고사양 플랜의 모든 기능을 써보고 결정하세요!
        </p>
        <p className="flex items-center gap-[4px]">
          {' '}
          <img
            src="assets/img/check_circle_blue.png"
            alt="check"
            width={24}
            height={24}
          />
          영상 파일 다운로드는 물론, 유튜브 자동 업로드까지!
        </p>
      </div>
    </div>
  )
}

export const DefaultModal = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  return (
    <div className="md:w-[1000px] md:h-[208px] mt-[40px] p-5 md:px-[32px] md:py-[16px] flex flex-col md:flex-row items-center justify-between border-[2px] border-href rounded-[20px]">
      <div className="flex flex-col items-center">
        <p className="text-href font-bold md:text-[24px]">프리미엄 플랜 기능</p>
        <p className="font-[800] md:text-[32px] text-[20px]">무료 체험</p>
      </div>
      <div className="md:text-[18px] text-[14px] font-bold flex flex-col gap-[16px] mt-[22px] md:mt-0">
        <p className="flex items-center gap-2">
          {' '}
          <img
            src="assets/img/check_circle_blue.png"
            alt="check"
            width={24}
            height={24}
          />
          최고사양 플랜의 모든 기능을 써보고 결정하세요!
        </p>
        <p className="flex items-center gap-2">
          {' '}
          <img
            src="assets/img/check_circle_blue.png"
            alt="check"
            width={24}
            height={24}
          />
          영상 파일 다운로드는 물론, 유튜브 자동 업로드까지!
        </p>
      </div>
      <div className="flex flex-col gap-[8px] font-bold text-[18px] mt-[22px] md:mt-0">
        <button
          className="w-[264px] h-[60px]  border-href border-[2px] rounded-[40px] text-href"
          onClick={() => {
            SetFreePlanModal((prev: any) => !prev)
          }}
        >
          체험내용 상세보기
        </button>
        <FreeButton
          className="w-[264px] h-[60px] bg-href  rounded-[40px] text-white"
          onClick={paymentFreePlan}
        >
          지금 바로 체험하기
        </FreeButton>
      </div>
    </div>
  )
}

export const SecretModalHome = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  const navigate = useNavigate()
  return isMobile ? (
    <>
      <div className="w-[375px] bg-[#464DEE] flex flex-col items-center py-5 gap-1 ">
        <div className="flex flex-col items-center">
          <p className="w-[112px] h-[29px] rounded-[20px] bg-black text-white font-bold text-[14px] flex items-center justify-center">
            기간 한정 혜택
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            쉿! 당신에게만 드린
            <br /> 시크릿 특급혜택
          </p>
        </div>
        <div className="bg-[#6D64FF] w-[333px] h-[134px] rounded-[20px] flex items-center px-4  py-[15px] gap-3">
          <img
            src="assets/img/coupon/clock.png"
            alt="pack"
            width={63}
            height={70}
          />
          <div className="flex flex-col">
            <p className="font-bold text-white text-left">
              잊지 마세요! <br /> 한 달 후 사라지는 혜택이 있어요!
            </p>
            <p className="text-[14px] text-white">
              프리미엄 영상 50개 무료
              <br />
              체험 플랜 미션 & 연결제 50% 할인 쿠폰
              <br />
              미션 달성 경품 이벤트
            </p>
          </div>
        </div>

        <div>
          <button
            className="text-[#735CFF]  rounded-[40px] bg-white font-bold w-[335px] h-[40px]"
            onClick={() => {
              navigate('/coupon-pack')
            }}
          >
            내 쿠폰팩 확인하기
          </button>
        </div>
      </div>
    </>
  ) : (
    // PC
    <div className="md:w-[816x] md:h-[171.45px] w-full  bg-[#464DEE] flex flex-col md:flex-row rounded-[20px] items-center px-5 justify-evenly mt-[40px] gap-[31.5px]">
      <div className="flex flex-col items-center">
        <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
          기간 한정 혜택
        </p>
        <p className="font-[800] text-[24px] text-white text-center">
          쉿! 당신에게만 드린
          <br /> 시크릿 특급혜택
        </p>
      </div>
      <div className="bg-[#6D64FF] w-[355px] h-[134px] rounded-[20px] flex items-center px-4  py-[15px] gap-3">
        <img
          src="assets/img/coupon/clock.png"
          alt="pack"
          width={85}
          height={94}
        />
        <div className="flex flex-col">
          <p className="font-bold text-white text-left">
            잊지 마세요! <br /> 한 달 후 사라지는 혜택이 있어요!
          </p>
          <p className="text-[14px] text-white">
            프리미엄 영상 50개 무료
            <br />
            체험 플랜 미션 & 연결제 50% 할인 쿠폰
            <br />
            미션 달성 경품 이벤트
          </p>
        </div>
      </div>

      <div>
        <button
          className="text-[#735CFF] px-[20px] py-[19px] rounded-[40px] bg-white font-bold"
          onClick={() => {
            navigate('/coupon-pack')
          }}
        >
          내 쿠폰팩 확인하기
        </button>
      </div>
    </div>
  )
}

export const SecretModal = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  const navigate = useNavigate()
  return isMobile ? (
    <>
      {' '}
      <div className="w-[375px] bg-[#464DEE] flex flex-col items-center py-5 gap-1 ">
        <div className="flex flex-col items-center">
          <p className="w-[112px] h-[29px] rounded-[20px] bg-black text-white font-bold text-[14px] flex items-center justify-center">
            기간 한정 혜택
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            쉿! 당신에게만 드린
            <br /> 시크릿 특급혜택
          </p>
        </div>
        <div className="bg-[#6D64FF] w-[333px] h-[134px] rounded-[20px] flex items-center px-4  py-[15px] gap-3">
          <img
            src="assets/img/coupon/clock.png"
            alt="pack"
            width={63}
            height={70}
          />
          <div className="flex flex-col">
            <p className="font-bold text-white text-left">
              잊지 마세요! <br /> 한 달 후 사라지는 혜택이 있어요!
            </p>
            <p className="text-[14px] text-white">
              프리미엄 영상 50개 무료
              <br />
              체험 플랜 미션 & 연결제 50% 할인 쿠폰
              <br />
              미션 달성 경품 이벤트
            </p>
          </div>
        </div>

        <div>
          <button
            className="text-[#735CFF]  rounded-[40px] bg-white font-bold w-[335px] h-[40px]"
            onClick={() => {
              navigate('/coupon-pack')
            }}
          >
            내 쿠폰팩 확인하기
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      {' '}
      <div className="md:w-[1000px] md:h-[171.45px] w-full  bg-[#464DEE] flex flex-col md:flex-row rounded-[20px] items-center px-5 justify-evenly mt-[40px]">
        <div className="flex flex-col items-center">
          <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
            기간 한정 혜택
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            쉿! 당신에게만 드린
            <br /> 시크릿 특급혜택
          </p>
        </div>
        <div className="bg-[#6D64FF] w-[422px] h-[118px] rounded-[20px] flex items-center px-5  py-[15px] gap-3">
          <img
            src="assets/img/coupon/clock.png"
            alt="pack"
            width={85}
            height={94}
          />
          <div className="flex flex-col">
            <p className="font-bold text-white">
              잊지 마세요! 한 달 후 사라지는 혜택이 있어요!
            </p>
            <p className="text-[14px] text-white">
              프리미엄 영상 50개 무료
              <br />
              체험 플랜 미션 & 연결제 50% 할인 쿠폰
              <br />
              미션 달성 경품 이벤트
            </p>
          </div>
        </div>

        <div>
          <button
            className="text-[#735CFF] px-[34px] py-[19px] rounded-[40px] bg-white font-bold"
            onClick={() => {
              navigate('/coupon-pack')
            }}
          >
            내 쿠폰팩 확인하기
          </button>
        </div>
      </div>
    </>
  )
}
export const NoUserModalHome = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  const navigate = useNavigate()
  return isMobile ? (
    <>
      <div className="w-[375px] h-[313px] bg-[#464DEE] flex flex-col items-center py-5 gap-1">
        <div className="flex flex-col items-center">
          <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
            신규 회원 1,000명 선착순
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            총 150만원
            <br /> 특급혜택 시크릿 쿠폰팩
          </p>
        </div>
        <div className="flex ">
          <img
            src="assets/img/coupon/coupon_pack.png"
            alt="pack"
            width={154}
            height={117}
          />
          <div className="flex flex-col text-[14px] text-white">
            <ul className=" list-disc ml-4">
              <li>프리미엄 영상 50개 무료</li>
              <li>체험 미션 참여</li>
              <li>미션 달성 경품 이벤트</li>
              <li>연결제 50% 할인 쿠폰</li>
              <li>초대코드 캐시적립</li>
            </ul>
            <span>기타 등등</span>
          </div>
        </div>

        <div>
          <button
            className="text-[#4360EC] w-[335px] h-[40px] rounded-[40px] bg-white font-bold"
            onClick={() => {
              navigate('/sign-up')
            }}
          >
            가입하고 쿠폰팩 뽑기
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      {/* PC */}
      <div className="md:w-[816px] md:h-[171.45px] bg-[#464DEE] flex rounded-[20px] items-center px-5 justify-evenly">
        <div className="flex flex-col items-center">
          <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
            신규 회원 1,000명 선착순
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            총 150만원
            <br /> 특급혜택 <br />
            시크릿 쿠폰팩
          </p>
        </div>
        <div>
          <img
            src="assets/img/coupon/coupon_pack.png"
            alt="pack"
            width={200}
            height={151}
          />
        </div>
        <div className="flex flex-col text-[14px] text-white">
          <ul className=" list-disc ml-4">
            <li>프리미엄 영상 50개 무료</li>
            <li>체험 미션 참여</li>
            <li>미션 달성 경품 이벤트</li>
            <li>연결제 50% 할인 쿠폰</li>
            <li>초대코드 캐시적립</li>
          </ul>
          <span>기타 등등</span>
        </div>
        <div>
          <button
            className="text-[#4360EC] px-[34px] py-[19px] rounded-[40px] bg-white font-bold"
            onClick={() => {
              navigate('/sign-up')
            }}
          >
            가입하고 쿠폰팩 뽑기
          </button>
        </div>
      </div>
    </>
  )
}

export const NoUserModal = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  const navigate = useNavigate()
  return isMobile ? (
    <>
      <div className="w-[375px] h-[313px] bg-[#464DEE] flex flex-col items-center py-5 gap-1 mt-10">
        <div className="flex flex-col items-center">
          <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
            신규 회원 1,000명 선착순
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            총 150만원
            <br /> 특급혜택 시크릿 쿠폰팩
          </p>
        </div>
        <div className="flex ">
          <img
            src="assets/img/coupon/coupon_pack.png"
            alt="pack"
            width={154}
            height={117}
          />
          <div className="flex flex-col text-[14px] text-white">
            <ul className=" list-disc ml-4">
              <li>프리미엄 영상 50개 무료</li>
              <li>체험 미션 참여</li>
              <li>미션 달성 경품 이벤트</li>
              <li>연결제 50% 할인 쿠폰</li>
              <li>초대코드 캐시적립</li>
            </ul>
            <span>기타 등등</span>
          </div>
        </div>

        <div>
          <button
            className="text-[#4360EC] w-[335px] h-[40px] rounded-[40px] bg-white font-bold"
            onClick={() => {
              navigate('/sign-up')
            }}
          >
            가입하고 쿠폰팩 뽑기
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="md:w-[1000px] md:h-[171.45px] bg-[#464DEE] flex rounded-[20px] items-center px-5 justify-evenly mt-10">
        <div className="flex flex-col items-center">
          <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
            신규 회원 1,000명 선착순
          </p>
          <p className="font-[800] text-[24px] text-white text-center">
            총 150만원
            <br /> 특급혜택 시크릿 쿠폰팩
          </p>
        </div>
        <div>
          <img
            src="assets/img/coupon/coupon_pack.png"
            alt="pack"
            width={200}
            height={151}
          />
        </div>
        <div className="flex flex-col text-[14px] text-white">
          <ul className=" list-disc ml-4">
            <li>프리미엄 영상 50개 무료</li>
            <li>체험 미션 참여</li>
            <li>미션 달성 경품 이벤트</li>
            <li>연결제 50% 할인 쿠폰</li>
            <li>초대코드 캐시적립</li>
          </ul>
          <span>기타 등등</span>
        </div>
        <div>
          <button className="text-[#4360EC] px-[34px] py-[19px] rounded-[40px] bg-white font-bold">
            가입하고 쿠폰팩 뽑기
          </button>
        </div>
      </div>
    </>
  )
}

export const NoUserModalMyPage = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  return isMobile ? (
    <></>
  ) : (
    // PC
    <div className="w-[816px] bg-[#464DEE] flex rounded-[20px] items-center px-5 justify-evenly">
      <div className="flex flex-col items-center">
        <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
          신규 회원 1,000명 선착순
        </p>
        <p className="font-[800] text-[24px] text-white text-center">
          총 150만원
          <br /> 특급혜택 시크릿 쿠폰팩
        </p>
      </div>
      <div>
        <img
          src="assets/img/coupon/coupon_pack.png"
          alt="pack"
          width={200}
          height={151}
        />
      </div>
      <div className="flex flex-col text-[14px] text-white">
        <ul className=" list-disc ml-4">
          <li>프리미엄 영상 50개 무료</li>
          <li>체험 미션 참여</li>
          <li>미션 달성 경품 이벤트</li>
          <li>연결제 50% 할인 쿠폰</li>
          <li>초대코드 캐시적립</li>
        </ul>
        <span>기타 등등</span>
      </div>
      <div>
        <button className="text-[#4360EC] px-[34px] py-[19px] rounded-[40px] bg-white font-bold">
          가입하고 쿠폰팩 뽑기
        </button>
      </div>
    </div>
  )
}

export const SecretModalMyPage = (props: any) => {
  const { paymentFreePlan, SetFreePlanModal } = props
  const navigate = useNavigate()
  return isMobile ? (
    <>
      <div className="w-[375px] flex px-5 py-[11px] bg-[#6D64FF]">
        <div className="mr-3">
          <img
            src="assets/img/coupon/clock.png"
            alt="pack"
            width={39}
            height={44}
          />
        </div>
        <div className="mr-[22px]">
          <p className="font-bold text-[14px] text-left text-[white]">
            한달 후 사라지는 <br /> 시크릿 특급혜택 잊지 마세요
          </p>
        </div>
        <div>
          <button
            className="text-[#735CFF] px-[14px] py-[12px] rounded-[40px] bg-white font-bold text-[12px]"
            onClick={() => {
              navigate('/coupon-pack')
            }}
          >
            내 쿠폰팩 확인 {`>`}
          </button>
        </div>
      </div>
    </>
  ) : (
    <>
      {/* PC */}
      <div className=" w-[816px]  h-[110px] bg-[#464DEE] flex flex-col md:flex-row rounded-[20px] items-center px-[30px] gap-[52px]   ">
        <div className="flex flex-col items-center">
          <p className="px-4 py-[6px] rounded-[20px] bg-black text-white font-bold text-[14px]">
            기간 한정 혜택
          </p>
          <p className="font-[800] text-[18px] text-white text-center">
            쉿! 당신에게만 드린
            <br /> 시크릿 특급혜택
          </p>
        </div>
        <div className="bg-[#6D64FF] w-[365px] h-[90px] rounded-[20px] flex items-center px-5  py-[10px] gap-3">
          <img
            src="assets/img/coupon/clock.png"
            alt="pack"
            width={64}
            height={70}
          />
          <div className="flex flex-col">
            <p className="font-bold text-white text-[14px]">
              잊지 마세요! 한 달 후 사라지는 혜택이 있어요!
            </p>
            <p className="text-[12px] text-white">
              프리미엄 영상 50개 무료
              <br />
              체험 플랜 미션 & 연결제 50% 할인 쿠폰
              <br />
              미션 달성 경품 이벤트
            </p>
          </div>
        </div>

        <div>
          <button
            className="text-[#735CFF] px-[22px] py-[16px] rounded-[40px] bg-white font-bold text-[14px]"
            onClick={() => {
              navigate('/coupon-pack')
            }}
          >
            내 쿠폰팩 확인하기
          </button>
        </div>
      </div>
    </>
  )
}
