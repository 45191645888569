import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useGetPaymentOrderRequests from '../../hooks/query/useGetPaymentOrderRequests'
import { UserType } from '../../utils/common'
import {
  AffiliateBenefit,
  CouponBenefit,
  InviteBenefit,
  PaymentOrderRequest,
} from '../../service/payment'
import useGetPlansPrice from '../../hooks/query/plans/useGetPlansPrice'
import { PaymentModal } from '../PaymentModal'
import { subscriptionType } from '../../service/plans'

export const PromotionCircle = (props: any) => {
  const { userInfo } = props

  const {
    isLoading: priceIsLoading,
    data: priceData,
    refetch: priceRefetch,
  } = useGetPlansPrice('annual')

  const navigate = useNavigate()
  const { mutate: getPaymentOrderRequestsMutate } = useGetPaymentOrderRequests()
  const [monthPrice, setMonthPrice] = useState(0)
  const [paymentModal, setPaymentModal] = useState<boolean>(false)
  const [planId, setPlanId] = useState(0)
  const [couponBenefit, setCouponBenefit] = useState<null | CouponBenefit>(null)
  const [paymentOrderRequest, setPaymentOrderRequest] =
    useState<PaymentOrderRequest>({
      orderId: '',
      productName: '',
      subscriptionType: '',
      price: 0,
      vat: 0,
      id: 0,
    })
  const [affiliateBenefit, setAffiliateBenefit] =
    useState<null | AffiliateBenefit>(null)
  const [inviteeBenefit, setInviteBenefit] = useState<null | InviteBenefit>(
    null
  )
  const getPaymentOrderRequests = () => {
    const upgradePriceInfoArr = priceData?.prices?.filter(
      (el) => el.name === userInfo?.subscriptions?.planName
    )

    if (upgradePriceInfoArr) {
      const planId = upgradePriceInfoArr[0].id
      setPlanId(planId)
      setMonthPrice(Number(userInfo?.subscriptions?.price))
      const planPriceId = upgradePriceInfoArr[0].planPrice[0].id
      if (planId) {
        getPaymentOrderRequestsMutate(
          { planId, planPriceId },
          {
            onSuccess: (data) => {
              setPaymentOrderRequest(data.orderRequest)
              if (data.affiliateBenefit) {
                setAffiliateBenefit(data.affiliateBenefit)
              }
              if (data.inviteBenefit) {
                setInviteBenefit(data.inviteBenefit)
              }
              setPaymentModal(true)
            },
            onError: (e) => {
              console.log(e)
            },
          }
        )
      }
    }
  }

  const upgradeUserCondition = () => {
    if (
      userInfo?.subscriptions?.subscriptionType === subscriptionType.MONTHLY &&
      (userInfo?.userType === UserType.라이트 ||
        userInfo?.userType === UserType.프로 ||
        userInfo?.userType === UserType.프리미엄)
    ) {
      return false
    }

    return true
  }
  useEffect(() => {}, [paymentModal])

  return upgradeUserCondition() ? (
    <></>
  ) : (
    <>
      <div
        className="bg-main  text-white  w-full h-[103px] rounded-[40px] flex md:flex-row md:justify-start  items-center px-3 md:px-[33px]  md:text-base justify-center font-bold text-[18px] cursor-pointer  mt-[8px]"
        onClick={() => {
          getPaymentOrderRequests()
        }}
      >
        <div className="w-[86.76px] h-[69.8px]">
          <img src="assets/img/icon_upgrade.png" alt="upgrade" className="" />
        </div>
        <div className="flex flex-col ml-[8px]">
          <p className="font-bold text-[18px] md:text-[18px] leading-[21.6px]">
            지금 연간 플랜으로 업그레이드 하면 <br />
            <span className="text-[24px] leading-[28.8px]">
              40% 할인! {`>>`}
            </span>
          </p>
        </div>
      </div>
      {paymentModal && (
        <PaymentModal
          setPaymentModal={setPaymentModal}
          paymentOrderRequest={paymentOrderRequest}
          affiliateBenefit={affiliateBenefit}
          couponBenefit={couponBenefit}
          affiliatePlan={userInfo?.affiliate?.plan}
          inviteBenefit={inviteeBenefit}
          userInfo={userInfo?.userInfo}
          planId={planId}
          type={'annual'}
          upgrade={true}
          monthPrice={monthPrice}
        />
      )}
    </>
  )
}
