import React from 'react'
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

interface DonutChartProps {
  current: number
  total: number
  color1?: string
  color2?: string
  textColor1?: string
  textColor2?: string
  textColor3?: string
}

export const LongVideoMonthlyDonutChart: React.FC<DonutChartProps> = ({
  current,
  total,
  color1,
  color2,
  textColor1,
  textColor2,
  textColor3,
}) => {
  const percentage =
    Number(current) === 0.0
      ? 0
      : ((Number(current) / Number(total)) * 100).toFixed(1)

  const data = [
    { name: 'Progress', value: Number(percentage) },
    { name: 'Remaining', value: 100 - Number(percentage) },
  ]

  return (
    <ResponsiveContainer width={150} height={150}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={45} // 도넛 두께 증가
          outerRadius={70} // 도넛이 150x150에 꽉 차도록 설정
          startAngle={90}
          endAngle={-270}
          dataKey="value"
        >
          <Cell key="progress" fill="url(#gradientProgress)" />
          <Cell key="remaining" fill="#E0E0E0" />
        </Pie>

        {/* 그라데이션 정의 */}
        <defs>
          <linearGradient id="gradientProgress" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor={color1} />
            <stop offset="100%" stopColor={color2} />
          </linearGradient>
        </defs>

        {/* 중앙 텍스트 */}
        <text
          x="59"
          y="47%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="14"
          fontWeight="bold"
          fill={textColor1}
        >
          {`${current}개`}
        </text>
        <text
          x="90"
          y="47%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="14"
          fontWeight="bold"
          fill={textColor2}
        >
          {`/ ${total}개`}
        </text>
        <text
          x="52%"
          y="60%"
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="16"
          fontWeight="bold"
          fill={textColor3}
        >
          {`${percentage}%`}
        </text>
      </PieChart>
    </ResponsiveContainer>
  )
}

export const LongVideoMonthlyMobileDonutChart: React.FC<DonutChartProps> = ({
  current,
  total,
  color1,
  color2,
  textColor1,
  textColor2,
  textColor3,
}) => {
  const percentage =
    Number(current) === 0.0
      ? 0
      : ((Number(current) / Number(total)) * 100).toFixed(1)

  const data = [
    { name: 'Progress', value: Number(percentage) },
    { name: 'Remaining', value: 100 - Number(percentage) },
  ]

  return (
    <ResponsiveContainer width={78} height={78}>
      <PieChart>
        <Pie
          data={data}
          innerRadius={15} // 적당한 도넛 두께 설정
          outerRadius={35} // 컨테이너에 맞추어 설정
          startAngle={90}
          endAngle={-270}
          dataKey="value"
        >
          <Cell key="progress" fill="url(#gradientProgress)" />
          <Cell key="remaining" fill="#E0E0E0" />
        </Pie>

        {/* 그라데이션 정의 */}
        <defs>
          <linearGradient id="gradientProgress" x1="0" y1="0" x2="1" y2="1">
            <stop offset="0%" stopColor={color1} />
            <stop offset="100%" stopColor={color2} />
          </linearGradient>
        </defs>
      </PieChart>
    </ResponsiveContainer>
  )
}
