import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts'
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent'

interface ChartData {
  round: number
  usedCount: number
  maxCount: number
  remain: number
}
export const LongVideoMonthlyBarChart = (props: any) => {
  const { chartData } = props
  const max = 80
  const generateMonthlyData = () => {
    if (!chartData) return
    return chartData?.map((el: any) => {
      return {
        round: el?.round,
        usedCount: el?.usedCount,
        maxCount: el?.maxCount,
        remain: el?.maxCount - el?.usedCount,
      }
    })
  }

  const data = generateMonthlyData()

  const CustomTooltip: React.FC<TooltipProps<ValueType, NameType>> = ({
    active,
    payload,
    label,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: '#fff',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}
        >
          <p>{`${label}개월 차`}</p>
          <p>{`${payload[0].value}개 요청`}</p>
        </div>
      )
    }

    return null
  }
  return (
    <ResponsiveContainer width={284} height={104}>
      <BarChart data={data} margin={{}}>
        <XAxis
          dataKey="date"
          ticks={[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '7',
            '8',
            '9',
            '10',
            '11',
            '12',
          ]}
          tick={{ fontSize: 12, fill: '#D4D9DF' }}
          axisLine={false} // X축 라인 제거
          tickLine={false} // X축 눈금선 제거
        />

        {/* Y축 숨기기 */}
        <YAxis hide />
        <Bar dataKey="usedCount" radius={[0, 0, 10, 10]} stackId="a">
          {data.map((entry: any, index: number) => (
            <Cell
              key={`cell-${index}`}
              width={12}
              fill={'url(#gradientHighlighted)'}
            />
          ))}
        </Bar>
        <Bar dataKey="remain" radius={[10, 10, 0, 0]} width={12} stackId="a">
          {data.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} width={12} fill="#E9EDF7" />
          ))}
        </Bar>

        {/* 그라데이션 정의 */}
        <defs>
          <linearGradient id="gradientHighlighted" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="rgba(66, 98, 254, 1)" />
            <stop offset="95%" stopColor="rgba(192, 89, 255, 1)" />
          </linearGradient>
        </defs>
      </BarChart>
    </ResponsiveContainer>
  )
}
