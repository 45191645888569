import { useMutation } from '@tanstack/react-query'
import React from 'react'
import { checkBlogExists, checkYoutubeExists } from '../../../service/event'

export const useCheckYoutubeExistence = () => {
  return useMutation({
    mutationKey: ['check-youtube-existence'],
    mutationFn: checkYoutubeExists,
  })
}
