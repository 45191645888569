import { useNavigate } from 'react-router-dom'
import { EmptyBarChart } from '../chart/EmptyBarChart'
import {
  CouponToolitip,
  CouponToolitip2,
  FreeCouponToolitip,
  PercentyCouponToolitip,
} from '../shortvideo/CouponToolitip'
import { useEffect, useState } from 'react'
import {
  LongVideoMonthlyDonutChart,
  LongVideoMonthlyMobileDonutChart,
} from '../chart/\bLongVideoMonthlyDonutChart'
import { LongVideoDailyBarChart } from '../chart/DailyBarChart'
import { LongVideoMonthlyBarChart } from '../chart/LongVideoMonthlyBarChart copy'
import { useCountdown, UserType } from '../../utils/common'
import { isMobile } from 'react-device-detect'
import classNames from 'classnames'
import useGetUsageMonthly from '../../hooks/query/plans/useGetUsageMonthly'
import useGetUsageAnnual from '../../hooks/query/plans/useGetUsageAnnual'
import { useLuckyDraw } from '../../hooks/query/point/useLuckyDraw'
import CountUp from 'react-countup'
import useUsageStore from '../../store/useUsageStore'

export const LightPlan = (props: any) => {
  const { subscriptionType, startDate, endDate, currentMonth } = props
  const [couponTooltipStatus, setCouponTooltipStatus] = useState(false)
  const navigate = useNavigate()
  const pDate = new Date('20' + endDate + ' 23:59:59')

  // 카운트다운을 조건별로 진행

  const { formattedTime } = useCountdown(pDate, true)
  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }
  return (
    <>
      <div className="flex flex-col md:w-[325px] w-[315px] p-5 bg-white rounded-[20px] justify-evenly items-center gap-2 relative">
        <p className="font-bold w-full text-left flex items-center">
          <img
            src="assets/img/check_circle.png"
            alt="check_circle"
            width={15}
            height={15}
            className="mr-2"
          />
          <div>
            <span className="text-main">
              라이트{' '}
              {subscriptionType === 'annual'
                ? '1년'
                : subscriptionType === 'monthly'
                ? '1개월'
                : ''}
            </span>{' '}
            {` `}
            <span> 사용중</span>
          </div>
        </p>
        <p className="text-[14px] w-full text-left">
          <span className="font-bold">
            {' '}
            {subscriptionType === 'annual'
              ? `${currentMonth}`
              : subscriptionType === 'monthly'
              ? '기간'
              : ''}
          </span>{' '}
          <span className="text-[#B6BBC6]">
            {' '}
            {startDate} ~ {endDate}
          </span>
        </p>

        {subscriptionType === 'annual' ? (
          <>
            {' '}
            <EmptyBarChart
              barCount={12}
              barWidth={12}
              isXAxis={false}
              chartHeight={100}
            />
            <button
              className="font-bold text-[12px]  text-center bg-[#E8FDFD] rounded-[40px] d:w-[215px] md:h-[30px] w-[275px] h-[33px]
              flex items-center justify-center absolute z-50 md:left-14 left-4 top-[50%]"
              onClick={moveToPriceGuide}
            >
              <p>
                {' '}
                플랜 업그레이드 하고{' '}
                <span className="text-main"> 럭키드로우 참여 {`>`}</span>
              </p>
            </button>
          </>
        ) : (
          <>
            {' '}
            <EmptyBarChart
              barCount={30}
              barWidth={4}
              isXAxis={true}
              chartHeight={80}
            />
            <button
              className="font-bold text-[12px]  text-center bg-[#E8FDFD] rounded-[40px] md:w-[215px] md:h-[30px] w-[275px] h-[33px]
               flex items-center justify-center absolute z-50 md:left-14 left-4  top-[57.5%]"
              onClick={moveToPriceGuide}
            >
              <p>
                {' '}
                플랜 업그레이드 하고{' '}
                <span className="text-main"> 럭키드로우 참여 {`>`}</span>
              </p>
            </button>
          </>
        )}
      </div>
      <div className="flex  w-full md:p-5 p-[10px] bg-white rounded-[20px] items-center gap-2 justify-between">
        <div
          className="bg-[#E8FDFD] h-[135px] w-[147px] rounded-[16px] relative flex justify-center flex-col items-center gap-2 cursor-pointer py-[11px]"
          onClick={moveToPriceGuide}
        >
          <img
            src="assets/img/lock.png"
            alt="lock"
            width={98}
            height={98}
            className=" absolute md:left-7 left-5 -top-8"
          />
          <p className="h-[45px]"></p>
          <p className="text-center font-bold text-[12px] text-main leading-[14.32px]  ">
            플랜 업그레이드 하고
            <br /> 럭키드로우 받으세요!
          </p>
          <button className="bg-main font-bold text-white text-[12px] rounded-[40px] w-[127px] h-[30px]">
            {' '}
            업그레이드
          </button>
        </div>
        <div className="flex flex-col w-[195px]  items-end">
          <p className="bg-[#FFF6E6] py-1 px-3 font-bold text-[12px] text-center mb-2 rounded-[10px]">
            종료까지 <span className="text-error">{formattedTime}</span>
          </p>
          <p className="font-bold mb-2 md:text-base text-[14px]">
            이번 라운드 럭키드로우
          </p>
          <p
            className="mb-4  bg-cover bg-center rounded-[6px] flex justify-center items-center w-[145px] h-[41px] text-[20px] font-bold text-white "
            style={{
              backgroundImage: "url('assets/img/gray_balance.png')",
            }}
          >
            ???????
          </p>
          <p
            className="text-[12px] text-[#FF8800] font-bold cursor-pointer"
            onClick={() => {
              setCouponTooltipStatus((prev) => !prev)
            }}
          >
            {' '}
            미션 자세히 보기 {`>`}
          </p>
        </div>
      </div>
      <CouponToolitip
        couponTooltipStatus={couponTooltipStatus}
        setCouponTooltipStatus={setCouponTooltipStatus}
      />
    </>
  )
}

export const ProAndPremiumPlanMonthly = (props: any) => {
  const {
    userType,
    subscriptionType,
    startDate,
    endDate,
    currentMonth,
    totalLimit,
    usedCount,
    available,
    couponBenefit,
    isDone,
  } = props

  const { data: UsageData, isPending, refetch } = useGetUsageMonthly()
  const setRefetch = useUsageStore((state: any) => state.setRefetch)

  const [couponTooltipStatus, setCouponTooltipStatus] = useState(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const navigate = useNavigate()
  const mDate = new Date(couponBenefit?.until)
  const pDate = new Date('20' + endDate + ' 23:59:59')

  // 카운트다운을 조건별로 진행
  const targetDate = isDone ? mDate : pDate
  const { formattedTime } = useCountdown(targetDate, true)
  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }
  useEffect(() => {
    if (refetch && typeof refetch === 'function') {
      setRefetch(refetch) // refetch가 함수일 때만 설정
    } else {
      console.log('usage Refetch not ready:', refetch) // 초기 상태 확인
    }
  }, [refetch, setRefetch])

  return (
    <>
      <div className="flex flex-col  md:w-[325px] w-[315px]  p-5 bg-white rounded-[20px]  items-center gap-2 relative  h-[210px] md:h-auto">
        <p className="font-bold w-full text-left flex items-center">
          <img
            src="assets/img/check_circle.png"
            alt="check_circle"
            width={15}
            height={15}
            className="mr-2"
          />
          <div>
            <span className="text-main">
              {userType === UserType.프로 ? '프로' : ''}
              {userType === UserType.프리미엄 ? '프리미엄' : ''} 1개월
            </span>{' '}
            {` `}
            <span> {isDone ? '사용 완료' : '사용중'}</span>
          </div>
        </p>
        <p className="text-[14px] w-full text-left ">
          <span className="font-bold mr-1"> 기간</span>{' '}
          <span className="text-[#B6BBC6]">
            {' '}
            {startDate} ~ {endDate}
          </span>
        </p>
        {UsageData?.charts && (
          <LongVideoDailyBarChart barWidth={4} chartData={UsageData?.charts} />
        )}
      </div>
      <div className="flex   p-5 bg-white rounded-[20px] items-center gap-2">
        {/* 왼쪽  */}
        {isMobile ? (
          <>
            <div className="flex flex-col">
              {' '}
              <LongVideoMonthlyMobileDonutChart
                current={usedCount}
                total={totalLimit}
                color1={isDone ? '#91FFCA' : '#FFF6B7'}
                color2={isDone ? '#009FB1' : '#F6416C'}
              />
              <div className="flex flex-col items-center">
                <p className="font-bold text-[14px]">
                  <span
                    className={classNames('', {
                      'text-[#FF8800]': !isDone,
                      'text-[#00B7B9]': isDone,
                    })}
                  >
                    {usedCount}개
                  </span>
                  <span className="text-[#D4D9DF]"> / {totalLimit}개</span>
                </p>
                <p
                  className={classNames('font-bold', {
                    'text-[#FF8800]': !isDone,
                    'text-[#00B7B9]': isDone,
                  })}
                >
                  {((usedCount / totalLimit) * 100).toFixed(1)}%
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <LongVideoMonthlyDonutChart
              current={usedCount}
              total={totalLimit}
              color1={isDone ? '#91FFCA' : '#FFF6B7'}
              color2={isDone ? '#009FB1' : '#F6416C'}
              textColor1={isDone ? '#00B7B9' : '#FF8800'}
              textColor2={'#D4D9DF'}
              textColor3={isDone ? '#00B7B9' : '#FF8800'}
            />
          </>
        )}
        {/* 오른쪽  */}
        <div className="flex flex-col w-[195px]  items-end">
          {isDone ? (
            <>
              <p className=" py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px] bg-error">
                할인 종료까지{' '}
                <span className="text-white ">{formattedTime}</span>
              </p>
            </>
          ) : (
            <>
              <p className="bg-[#FFF6E6] py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px]">
                미션 종료까지{' '}
                <span className="text-error">{formattedTime}</span>
              </p>
            </>
          )}

          {isDone ? (
            <p
              className={classNames('font-bold mb-2 flex items-center', {
                'cursor-pointer': isDone,
              })}
              onClick={moveToPriceGuide}
            >
              <img src="assets/img/money.png" width={18} height={20} alt="" />
              <span className="text-main font-bold md:text-base text-[14px]">
                결제 보너스 받기 {`>`}
              </span>
            </p>
          ) : (
            <p className="font-bold mb-2 md:text-base text-[14px]">
              다음 달 결제 보너스 혜택
            </p>
          )}

          <p
            className={classNames('mb-4', {
              'cursor-pointer': isDone,
            })}
            onClick={isDone && moveToPriceGuide}
          >
            <img
              src="assets/img/coupon/20percenty.png"
              alt=""
              width={107}
              height={55.16}
            />
          </p>
          {isDone ? (
            <>
              <p
                className={classNames('text-[12px] font-bold', {
                  'cursor-pointer': isDone,
                })}
                onClick={moveToPriceGuide}
              >
                <span className="text-[#FF8800]">3일간의</span> 할인 혜택을
                받으세요!
              </p>
            </>
          ) : (
            <>
              <p className="text-[12px] font-bold">
                <span className="text-[#FF8800]">
                  {totalLimit - usedCount}개
                </span>{' '}
                더 생성하면 할인 받을 수 있어요
              </p>
              <p
                className="text-[12px] text-[#FF8800] font-bold cursor-pointer"
                onClick={() => {
                  setCouponTooltipStatus((prev) => !prev)
                }}
              >
                {' '}
                자세히 알아보기 {`>`}
              </p>
            </>
          )}
        </div>
      </div>
      {subscriptionType === 'monthly' ? (
        <PercentyCouponToolitip
          couponTooltipStatus={couponTooltipStatus}
          setCouponTooltipStatus={setCouponTooltipStatus}
        />
      ) : (
        <CouponToolitip2
          couponTooltipStatus={couponTooltipStatus}
          setCouponTooltipStatus={setCouponTooltipStatus}
        />
      )}
    </>
  )
}

export const ProAndPremiumPlanAnnual = (props: any) => {
  const {
    userType,
    subscriptionType,
    startDate,
    endDate,
    currentMonth,
    totalLimit,
    usedCount,
    available,
    couponBenefit,
    isDone,
  } = props

  const { data: usageData, isPending, refetch } = useGetUsageAnnual()
  const { mutate, isPending: luckyDrawPending } = useLuckyDraw()
  const setRefetch = useUsageStore((state: any) => state.setRefetch)

  const [couponTooltipStatus, setCouponTooltipStatus] = useState(false)
  const [isSuccess, setSuccess] = useState<boolean>(false)
  const navigate = useNavigate()
  const mDate = new Date(usageData?.mission?.monthEndDate ?? '00')
  const pDate = new Date('20' + endDate + ' 23:59:59')

  // 카운트다운을 조건별로 진행
  const targetDate = usageData?.mission?.monthEndDate ? mDate : pDate
  const { formattedTime } = useCountdown(targetDate, true)

  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }
  const handleLuckyDraw = (achievementId: number) => {
    if (achievementId !== 0) {
      mutate(achievementId, {
        onSuccess: () => {
          refetch()
        },
        onError: () => {},
      })
    }
  }
  useEffect(() => {
    if (refetch && typeof refetch === 'function') {
      setRefetch(refetch) // refetch가 함수일 때만 설정
    } else {
      console.log('usage Refetch not ready:', refetch) // 초기 상태 확인
    }
  }, [refetch, setRefetch])

  return (
    <>
      <div className="flex flex-col  md:w-[325px] w-[315px]  p-5 bg-white rounded-[20px]  items-center gap-2 relative  h-[210px] md:h-auto">
        <p className="font-bold w-full text-left flex items-center">
          <img
            src="assets/img/check_circle.png"
            alt="check_circle"
            width={15}
            height={15}
            className="mr-2"
          />
          <div>
            <span className="text-main">
              {userType === UserType.프로
                ? '프로'
                : userType === UserType.프리미엄
                ? '프리미엄'
                : ''}
              {` `}
              1년
            </span>{' '}
            {` `}
            <span> 사용중 </span>
          </div>
        </p>
        <p className="text-[14px] w-full text-left ">
          <span className="font-bold mr-1"> {currentMonth ?? ''}</span>{' '}
          <span className="text-[#B6BBC6]">
            {' '}
            {startDate} ~ {endDate}
          </span>
        </p>

        {usageData?.charts && (
          <LongVideoMonthlyBarChart chartData={usageData?.charts} />
        )}
      </div>
      <div className="flex   p-5 bg-white rounded-[20px] items-center gap-2">
        {/* 왼쪽  */}
        {isMobile ? (
          <>
            <div className="flex flex-col">
              {' '}
              <LongVideoMonthlyMobileDonutChart
                current={usedCount}
                total={totalLimit}
                color1={usageData?.achievement ? '#91FFCA' : '#FFF6B7'}
                color2={usageData?.achievement ? '#009FB1' : '#F6416C'}
              />
              <div className="flex flex-col items-center">
                <p className="font-bold text-[14px]">
                  <span
                    className={classNames('', {
                      'text-[#FF8800]': !usageData?.achievement,
                      'text-[#00B7B9]': usageData?.achievement,
                    })}
                  >
                    {usedCount}개
                  </span>
                  <span className="text-[#D4D9DF]"> / {totalLimit}개</span>
                </p>
                <p
                  className={classNames('font-bold', {
                    'text-[#FF8800]': !usageData?.achievement,
                    'text-[#00B7B9]': usageData?.achievement,
                  })}
                >
                  {((usedCount / totalLimit) * 100).toFixed(1)}%
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <LongVideoMonthlyDonutChart
              current={usedCount}
              total={totalLimit}
              color1={isDone ? '#91FFCA' : '#FFF6B7'}
              color2={isDone ? '#009FB1' : '#F6416C'}
              textColor1={isDone ? '#00B7B9' : '#FF8800'}
              textColor2={'#D4D9DF'}
              textColor3={isDone ? '#00B7B9' : '#FF8800'}
            />
          </>
        )}
        {/* 오른쪽  */}
        <div className="flex flex-col w-[195px]  items-end">
          {usageData?.achievement && !usageData.mission.result ? (
            <>
              <p className=" py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px] bg-error">
                할인 종료까지{' '}
                <span className="text-white ">{formattedTime}</span>
              </p>
            </>
          ) : (
            !usageData?.achievement &&
            !usageData?.mission.result && (
              <>
                <p className="bg-[#FFF6E6] py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px]">
                  미션 종료까지{' '}
                  <span className="text-error">{formattedTime}</span>
                </p>
              </>
            )
          )}
          {usageData?.mission.result && (
            <>
              <p className="bg-[#FFF6E6] py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px]">
                미션 성공
              </p>
            </>
          )}
          <p className="font-bold mb-2 md:text-base text-[14px]">
            이번 라운드 럭키 드로우
          </p>

          {usageData?.mission.luckyDrawAvaliable ? (
            <p
              className="mb-4  bg-cover bg-center rounded-[6px] flex items-center w-[145px] h-[41px] text-[12px] font-bold text-white cursor-pointer "
              onClick={() => {
                handleLuckyDraw(usageData?.achievement?.id ?? 0)
              }}
              style={{
                backgroundImage: "url('assets/img/blue_balance.png')",
              }}
            >
              <span
                className="bg-cover bg-center w-[113px] h-[31px] ml-[5px] flex items-center justify-center "
                style={{
                  backgroundImage: "url('assets/img/luckydraw_bg.png')",
                }}
              >
                <span className="text-[18px] mr-[5px]"> 🎰</span> 선물 뽑기
              </span>
            </p>
          ) : (
            <p
              className="mb-4  bg-cover bg-center rounded-[6px] flex justify-center items-center w-[145px] h-[41px] text-[20px] font-bold text-white "
              style={{
                backgroundImage: "url('assets/img/blue_balance.png')",
              }}
            >
              {usageData?.mission.result ? (
                <>
                  +
                  <CountUp end={100} duration={3} separator="," />
                </>
              ) : (
                <>???????</>
              )}
            </p>
          )}

          {usageData?.achievement ? (
            <>
              {usageData.mission.result ? (
                <>
                  <p className={classNames('text-[12px] font-bold', {})}>
                    보너스 캐시를 받으셨어요!
                  </p>{' '}
                  <p className="text-[12px] text-[#8F929B] font-bold ">
                    {' '}
                    유효기간 1개월
                  </p>
                </>
              ) : (
                <>
                  <p className={classNames('text-[12px] font-bold', {})}>
                    보너스 선물을 뽑아보세요!
                  </p>
                  <p
                    className="text-[12px] text-[#FF8800] font-bold cursor-pointer"
                    onClick={() => {
                      setCouponTooltipStatus((prev) => !prev)
                    }}
                  >
                    {' '}
                    자세히 알아보기 {`>`}
                  </p>
                </>
              )}
            </>
          ) : (
            <>
              <p className="text-[12px] font-bold">
                <span className="text-[#FF8800]">
                  {totalLimit - usedCount}개
                </span>{' '}
                더 생성하면 할인 받을 수 있어요
              </p>
              <p
                className="text-[12px] text-[#FF8800] font-bold cursor-pointer"
                onClick={() => {
                  setCouponTooltipStatus((prev) => !prev)
                }}
              >
                {' '}
                자세히 알아보기 {`>`}
              </p>
            </>
          )}
        </div>
      </div>
      <CouponToolitip2
        couponTooltipStatus={couponTooltipStatus}
        setCouponTooltipStatus={setCouponTooltipStatus}
      />
    </>
  )
}

export const EmptyPlan = (props: any) => {
  const { monthly, period } = props
  const [couponTooltipStatus, setCouponTooltipStatus] = useState(false)
  const navigate = useNavigate()
  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }
  const moveToVideo = () => {
    navigate('/video-editor')
  }
  return (
    <>
      <div className="flex flex-col  md:w-[325px] w-[315px] p-5 bg-white rounded-[20px] justify-evenly items-center gap-1 relative">
        <p className="font-bold w-full text-left flex items-center">
          <img
            src="assets/img/check_circle.png"
            alt="check_circle"
            width={15}
            height={15}
            className="mr-2"
          />
          <div>아직 사용 플랜이 없어요</div>
        </p>
        <p className="text-[14px] w-full text-left mb-5">
          <span className="font-bold">기간</span>{' '}
          <span className="text-[#B6BBC6]">{'-- ~ --'}</span>
        </p>

        <button
          className="font-bold text-[14px]  text-center bg-[#E8FDFD] rounded-[40px] w-[227px] h-[33px] flex items-center justify-center absolute z-50 top-50 top-[60%]"
          onClick={moveToVideo}
        >
          <p>
            <span className="text-main"> 프리미엄 플랜 50개 </span>{' '}
            {`무료체험 하기 >`}
          </p>
        </button>
        <EmptyBarChart
          barCount={30}
          barWidth={4}
          isXAxis={true}
          chartHeight={80}
        />
      </div>
      <div className="flex  w-full md:p-5 p-[10px] bg-white rounded-[20px] items-center gap-2 justify-between">
        <div
          className="bg-[#EBEEFF]  md:w-[147px] md:h-[135px] w-[129px] h-[118px] rounded-[16px] relative flex justify-center flex-col items-center  cursor-pointer "
          onClick={moveToVideo}
        >
          <img
            src="assets/img/lock_blue.png"
            alt="lock"
            className=" absolute md:left-7 left-4 -top-7  md:-top-8 md:w-[98px] md:h-[98px] w-[85.82px] h-[85.82px]"
          />
          <p className="h-[45px]"></p>
          <p className="text-center font-bold text-[12px] text-[#4360EC] leading-[14.32px] mb-[3px]">
            플랜 시작 하고
            <br /> 보너스 캐시 받으세요
          </p>
          <button className="bg-[#4360EC] font-bold text-white text-[12px] rounded-[40px] md:w-[127px] md:h-[30px] w-[111.33px] h-[26.3px]">
            {' '}
            지금 시작하기
          </button>
        </div>
        <div className="flex flex-col w-[195px]  items-end">
          <p className="bg-[#FFF6E6] py-1 px-3 font-bold text-[12px] text-center mb-2 rounded-[10px]">
            미션 시작 전
          </p>
          <p className="font-bold mb-1 md:text-base text-[14px]">
            이번 라운드 럭키 드로우
          </p>
          <p
            className="mb-4  bg-cover bg-center rounded-[6px] flex justify-center items-center w-[145px] h-[41px] text-[20px] font-bold text-white "
            style={{
              backgroundImage: "url('assets/img/blue_balance.png')",
            }}
          >
            ???????
          </p>

          <p
            className="text-[12px] text-[#FF8800] font-bold cursor-pointer"
            onClick={() => {
              setCouponTooltipStatus((prev) => !prev)
            }}
          >
            {' '}
            럭키드로우 미션 자세히 보기 {`>`}
          </p>
        </div>
        <CouponToolitip
          couponTooltipStatus={couponTooltipStatus}
          setCouponTooltipStatus={setCouponTooltipStatus}
        />
      </div>
    </>
  )
}

export const FreePlan = (props: any) => {
  const {
    subscriptionType,
    startDate,
    endDate,
    currentMonth,
    totalLimit,
    usedCount,
    available,
    couponBenefit,
    isDone,
  } = props
  const { data, isPending, refetch } = useGetUsageMonthly()
  const setRefetch = useUsageStore((state: any) => state.setRefetch)
  // const usageSet = useUsageStore((state: any) => state.set)
  // usageSet({ usage: data })

  const [couponTooltipStatus, setCouponTooltipStatus] = useState(false)
  const [missionDate, setMissionDate] = useState('')
  const [planDate, setPlanDate] = useState('')
  const navigate = useNavigate()
  const moveToPriceGuide = () => {
    navigate('/price-guide')
  }
  const handleTooltip = () => {
    setCouponTooltipStatus((prev) => !prev)
  }
  const moveToChallenge = () => {
    navigate('/coupon-pack')
  }

  const mDate = new Date(couponBenefit?.until)
  const pDate = new Date('20' + endDate + ' 23:59:59')

  // 카운트다운을 조건별로 진행
  const targetDate = isDone ? mDate : pDate
  const { formattedTime } = useCountdown(targetDate, true)
  useEffect(() => {
    if (refetch && typeof refetch === 'function') {
      setRefetch(refetch) // refetch가 함수일 때만 설정
    } else {
      console.log('usage Refetch not ready:', refetch) // 초기 상태 확인
    }
  }, [refetch, setRefetch])

  return (
    <>
      <div className="flex flex-col  md:w-[325px] md:h-auto h-[210px] w-[315px] p-5 bg-white rounded-[20px]   items-center relative">
        <p className="font-bold w-full text-left flex items-center mb-2 ">
          <img
            src="assets/img/check_circle.png"
            alt="check_circle"
            width={15}
            height={15}
            className="mr-2"
          />
          <div>
            <span className="">
              체험 플랜 {available ? '사용중' : '사용 종료'}{' '}
            </span>{' '}
          </div>
        </p>
        <p className="flex flex-col items-start w-full mb-5 font-bold">
          <p className="text-[14px] w-full text-left ">
            <span className="font-bold mr-1">
              {' '}
              {subscriptionType === 'annual'
                ? `${currentMonth}라운드`
                : subscriptionType === 'monthly'
                ? '기간'
                : ''}
            </span>{' '}
            <span className="text-[#B6BBC6]">
              {' '}
              {startDate} ~ {endDate}
            </span>
          </p>
          <p className="text-[14px] w-full text-left flex font-bold">
            <span className="font-bold mr-1">현황</span>{' '}
            <p className="flex">
              <span className="text-main">요청 {usedCount}개</span>

              <span className="text-[#B6BBC6]"> / </span>
              <span className="text-[#B6BBC6]">{totalLimit}개</span>
            </p>
          </p>
        </p>
        {Number(usedCount) === 0 && (
          <button
            className="font-bold text-[14px]  text-center bg-main text-white rounded-[40px] w-[169px] h-[33px] flex items-center justify-center absolute z-50 top-50 top-[65%]"
            onClick={moveToChallenge}
          >
            <p>선착순 미션 참여 시작! 🎉</p>
          </button>
        )}

        {Number(usedCount) > 0 && data?.charts ? (
          <LongVideoDailyBarChart chartData={data.charts} barWidth={4} />
        ) : (
          <EmptyBarChart
            barCount={30}
            barWidth={4}
            isXAxis={true}
            chartHeight={80}
          />
        )}
      </div>
      <div className="flex   p-5 bg-white rounded-[20px] items-center gap-2">
        {/* 왼쪽  */}
        {isMobile ? (
          <>
            <div className="flex flex-col">
              {' '}
              <LongVideoMonthlyMobileDonutChart
                current={usedCount}
                total={totalLimit}
                color1={isDone ? '#91FFCA' : '#FFF6B7'}
                color2={isDone ? '#009FB1' : '#F6416C'}
              />
              <div className="flex flex-col items-center">
                <p className="font-bold text-[14px]">
                  <span
                    className={classNames('', {
                      'text-[#FF8800]': !isDone,
                      'text-[#00B7B9]': isDone,
                    })}
                  >
                    {usedCount}개
                  </span>
                  <span className="text-[#D4D9DF]"> / {totalLimit}개</span>
                </p>
                <p
                  className={classNames('font-bold', {
                    'text-[#FF8800]': !isDone,
                    'text-[#00B7B9]': isDone,
                  })}
                >
                  {((usedCount / totalLimit) * 100).toFixed(1)}%
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <LongVideoMonthlyDonutChart
              current={usedCount}
              total={totalLimit}
              color1={isDone ? '#91FFCA' : '#FFF6B7'}
              color2={isDone ? '#009FB1' : '#F6416C'}
              textColor1={isDone ? '#00B7B9' : '#FF8800'}
              textColor2={'#D4D9DF'}
              textColor3={isDone ? '#00B7B9' : '#FF8800'}
            />
          </>
        )}
        {/* 오른쪽  */}
        <div className="flex flex-col w-[195px]  items-end">
          {isDone ? (
            <>
              <p className=" py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px] bg-error">
                할인 종료까지{' '}
                <span className="text-white ">{formattedTime}</span>
              </p>
            </>
          ) : (
            <>
              <p className="bg-[#FFF6E6] py-1 px-2 font-bold text-[12px] text-center mb-2 rounded-[10px]">
                미션 종료까지{' '}
                <span className="text-error">{formattedTime}</span>
              </p>
            </>
          )}
          {!isDone ? (
            <>
              {' '}
              <p className="font-bold mb-2 md:text-base text-[14px]">
                시크릿 체험 미션 쿠폰
              </p>
            </>
          ) : (
            <>
              <p
                className="font-bold mb-2 md:text-base text-[14px] text-error cursor-pointer"
                onClick={couponBenefit && moveToPriceGuide}
              >
                💰 미션 성공 쿠폰 받기 {`>`}
              </p>
            </>
          )}

          <p
            className={classNames('mb-4 cursor-pointer', {})}
            onClick={isDone ? moveToPriceGuide : handleTooltip}
          >
            <img
              src="assets/img/coupon/50.png"
              alt=""
              width={107}
              height={55.16}
            />
          </p>
          {isDone ? (
            <>
              <p
                className={classNames('text-[12px] font-bold', {
                  'cursor-pointer': isDone,
                })}
                onClick={moveToPriceGuide}
              >
                <span className="text-[#FF8800]">3일간의</span> 할인 혜택을
                받으세요!
              </p>
            </>
          ) : (
            <>
              <p className="text-[12px] font-bold">
                <span className="text-[#FF8800]">
                  {totalLimit - usedCount}개
                </span>{' '}
                더 생성하면 할인 받을 수 있어요
              </p>
              <p
                className="text-[12px] text-[#FF8800] font-bold cursor-pointer"
                onClick={handleTooltip}
              >
                {' '}
                자세히 알아보기 {`>`}
              </p>
            </>
          )}
        </div>
        <FreeCouponToolitip
          couponTooltipStatus={couponTooltipStatus}
          setCouponTooltipStatus={setCouponTooltipStatus}
        />
      </div>
    </>
  )
}
