import { useQuery } from '@tanstack/react-query'
import { getBlogEvent } from '../../../service/event'

function useGetEventBlog() {
  return useQuery({
    queryKey: ['get-event-blog'],
    queryFn: () => getBlogEvent(),
    staleTime: 6 * 1000,
  })
}

export default useGetEventBlog
