import { useQuery } from '@tanstack/react-query'

import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useDeferredValue,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  loadPaymentWidget,
  PaymentWidgetInstance,
  ANONYMOUS,
} from '@tosspayments/payment-widget-sdk'
import {
  AffiliateBenefit,
  AffiliatePlan,
  CouponBenefit,
  DiscountType,
  InviteBenefit,
  PaymentOrderRequest,
} from '../service/payment'
import { UserInfoType } from '../service/user'
import { Input } from './atom/Input'
import { useDiscount } from '../hooks/query/useDiscount'
import { useSaveDiscount } from '../hooks/query/useSaveDiscount'
import { isMobile } from 'react-device-detect'
import { Modal } from './layout/Modal'
import { CSSTransition } from 'react-transition-group'

interface ModalProps {
  setPaymentModal: Dispatch<SetStateAction<boolean>>
  paymentOrderRequest: PaymentOrderRequest
  affiliateBenefit: AffiliateBenefit | null
  inviteBenefit: InviteBenefit | null
  affiliatePlan: AffiliatePlan[] | null
  couponBenefit: CouponBenefit | null
  userInfo: UserInfoType
  planId: number
  type: string
  upgrade?: boolean
  monthPrice?: number
}

export const PaymentModal = ({
  children,
  paymentOrderRequest,
  affiliateBenefit,
  couponBenefit,
  inviteBenefit,
  affiliatePlan,
  setPaymentModal,
  userInfo,
  planId,
  type,
  upgrade,
  monthPrice,
}: PropsWithChildren<ModalProps>) => {
  //? clientKey는 개발자센터의 결제위젯 연동 키 > 클라이언트 키로 바꾸세요.
  //? customerKey는 구매자와 1:1 관계로 무작위한 고유값을 생성하세요.

  const { mutate: updateDiscountMutate } = useSaveDiscount()
  const deferredValue = useDeferredValue(0)
  const [point, setPoint] = useState('')
  const [isSpendingPoint, setSependingPoint] = useState<boolean>(false)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [isUseInviteCode, setUseInviteCode] = useState<boolean>(false)
  const [isAffiliateBenefit, setAffiliateBenefit] = useState<boolean>(false)
  const [isCouponBenefit, setCouponBenefit] = useState<boolean>(false)
  const { mutate, isPending } = useDiscount()
  const [isModal, setModal] = useState<boolean>(false)

  const [isInvalidCode, setInvalidCode] = useState<boolean>(false)
  const [errorText, setErrorText] = useState({
    title: '',
    subtitle: <></>,
  })
  const [invitationCode, setInvitationCode] = useState<string>('')
  const [totalPrice, setTotalPrice] = useState<number>(
    paymentOrderRequest?.price + paymentOrderRequest?.vat
  )
  const [originPrice, setOriginPrice] = useState<number>(
    paymentOrderRequest?.price
  )
  const [originVat, setOriginVat] = useState<number>(paymentOrderRequest?.vat)
  const [modifyPrice, setModifyPrice] = useState<number>(
    paymentOrderRequest?.price
  )
  const [modifyVat, setModifyVat] = useState<number>(paymentOrderRequest?.vat)

  const [discountValue, setDiscountValue] = useState<number>(0)
  const clientKey = process.env.REACT_APP_KEY ? process.env.REACT_APP_KEY : ''

  const [isChecked, setIsChecked] = useState(false)
  const [discountData, setDiscountData] = useState<DiscountType>({
    originalPrice: 0,
    discountRate: 0,
    discountAmount: 0,
    discountedPrice: 0,
  })

  const toggleContent = () => {
    setOpen(!isOpen)
  }

  const handleChange = (event: any) => {
    setIsChecked(event.target.checked)
  }
  const handlePoint = (e: any) => {
    setSependingPoint(true)
    const value = e.target.value.replace(/[^0-9]/g, '')
    setPoint(value)
  }

  const handleInvalidCode = () => {
    if (!isAffiliateBenefit && !isCouponBenefit) {
      setUseInviteCode(false)
      setInvitationCode('')
      setInvalidCode(false)
      setDiscountData({
        originalPrice: 0,
        discountRate: 0,
        discountAmount: 0,
        discountedPrice: 0,
      })
    }

    //가격도 원래 가격으로 다시 돌려야함
  }

  const handleDiscount = () => {
    const data = { planId, subscriptionType: type, invitationCode }
    mutate(data, {
      onSuccess: (data) => {
        setUseInviteCode(true)
        setDiscountData(data)
        setInvalidCode(true)
      },
      onError: (e) => {
        const error: any = e
        const errorCode = error?.response?.data?.message
        setInvalidCode(false)
        if (errorCode === 450) {
          setErrorText({
            title: '💁🏻 할인 코드 오류',
            subtitle: (
              <>
                입력하신 할인 코드는 존재하지 않는 코드입니다. <br />
                코드를 다시 잘 확인해서 입력해주세요!
              </>
            ),
          })
        } else if (errorCode === 460) {
          setErrorText({
            title: '🗓 할인 코드 기한 만료',
            subtitle: (
              <>
                입력하신 할인 코드는 사용 기한이 만료되었습니다.
                <br />
                다른 할인 코드를 입력해주세요!
              </>
            ),
          })
        }
        setModal((prev) => !prev)
        console.log(e.message)
      },
    })
  }
  function usePaymentWidget(clientKey: string, customerKey: string) {
    return useQuery({
      queryKey: ['payment-widget', clientKey, customerKey],
      queryFn: () => {
        return loadPaymentWidget(clientKey, customerKey)
      },
    })
  }

  const { data: paymentWidget } = usePaymentWidget(
    clientKey,
    paymentOrderRequest.orderId
  )
  const paymentWidgetRef = useRef<PaymentWidgetInstance | null>(null)
  const paymentMethodsWidgetRef = useRef<ReturnType<
    PaymentWidgetInstance['renderPaymentMethods']
  > | null>(null)

  const handelPayment = () => {
    updateDiscountMutate(
      {
        id: paymentOrderRequest.id,
        discountValue,
        discountCode: invitationCode,
        upgrade,
        monthPrice,
      },
      {
        onSuccess: (data) => {
          try {
            paymentWidget?.requestPayment({
              orderId: paymentOrderRequest.orderId,
              orderName: `${paymentOrderRequest.productName} ${
                upgrade ? '(업그레이드)' : ''
              }`,
              customerName: userInfo.name,
              customerEmail: userInfo.email,
              successUrl: `${window.location.origin}/my-payments`,
              failUrl: `${window.location.origin}/payment-fail?path=price-guid`,
            })
          } catch (err) {
            console.log(err)
          }
        },
        onError: (e) => {
          const error: any = e
          console.log(error?.response?.data)
        },
      }
    )
    const paymentWidget = paymentWidgetRef.current
  }

  useEffect(() => {
    ;(async () => {
      const paymentWidget = await loadPaymentWidget(
        clientKey,
        paymentOrderRequest.orderId
      )
      const finalPrice = originPrice - (monthPrice ? Number(monthPrice) : 0)
      const vat = Number((finalPrice * 0.1).toFixed(0))
      let tPrice = finalPrice + vat

      setTotalPrice(tPrice)
      setModifyPrice(finalPrice)
      setModifyVat(vat)

      if (
        affiliateBenefit?.discountedPrice &&
        paymentOrderRequest.subscriptionType === 'annual'
      ) {
        setAffiliateBenefit((prev) => !prev)

        const { discountAmount, discountRate, discountedPrice, originalPrice } =
          affiliateBenefit
        const finalPrice =
          discountedPrice - (monthPrice ? Number(monthPrice) : 0)

        const vat = Number((finalPrice * 0.1).toFixed(0))
        tPrice = finalPrice + vat

        setTotalPrice(tPrice)
        setModifyPrice(finalPrice)
        setModifyVat(vat)
        setDiscountValue(originalPrice - discountedPrice)
        if (affiliatePlan) {
          setInvitationCode(affiliatePlan[0].affiliateCode)
        }
      }
      if (couponBenefit) {
        setCouponBenefit((prev) => !prev)

        const { discountAmount, discountRate, discountedPrice, originalPrice } =
          couponBenefit
        const finalPrice =
          discountedPrice - (monthPrice ? Number(monthPrice) : 0)

        const vat = Number((finalPrice * 0.1).toFixed(0))
        tPrice = finalPrice + vat

        setTotalPrice(tPrice)
        setModifyPrice(finalPrice)
        setModifyVat(vat)
        setDiscountValue(originalPrice - discountedPrice)
      }

      const paymentMethodsWidget = paymentWidget.renderPaymentMethods(
        '#payment-widget',
        tPrice
      )

      paymentWidgetRef.current = paymentWidget
      paymentMethodsWidgetRef.current = paymentMethodsWidget
    })()
  }, [])

  useEffect(() => {
    const paymentMethodsWidget = paymentMethodsWidgetRef.current

    if (paymentMethodsWidget == null) {
      return
    }
    // 유효한 코드가 되었을 경우에는 계산을
    let oPrice = originPrice
    let discountValue = discountData.discountedPrice
    let discountedPrice = discountData.discountedPrice
    if (!isInvalidCode) {
      discountedPrice = oPrice
    }

    //할인된 가격으로 다시 가격을 재계산 하는 구간 point는 덤

    const finalPrice = discountedPrice - Number(point) - Number(monthPrice)
    const vat = Number((finalPrice * 0.1).toFixed(0))
    const tPrice = finalPrice + vat

    paymentMethodsWidget.updateAmount(
      tPrice,
      paymentMethodsWidget.UPDATE_REASON.COUPON
    )
    if (isInvalidCode) {
      setDiscountValue(oPrice - discountData.discountedPrice)
    } else {
      setDiscountValue(0)
    }

    setTotalPrice(tPrice)
    setModifyPrice(finalPrice)
    setModifyVat(vat)
  }, [isInvalidCode, point])

  return (
    <div className="h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-black bg-opacity-50 text-center md:p-[40px] z-50">
      <div className="bg-white w-full md:w-[816px] px-[20px] md:px-[40px] border md:rounded-[20px] overflow-y-auto pb-[100px]">
        <div className="sticky top-0 bg-white z-10  pb-5 pt-10  ">
          <p className="flex justify-center items-center relative ">
            <p className="md:text-[48px] text-[32px] font-bold   ">
              플랜 결제 {upgrade && `(업그레이드)`}
            </p>
            <button
              onClick={() => {
                setPaymentModal(false)
              }}
              className=" absolute right-0"
            >
              <img
                src="assets/img/Icon_x.png"
                alt="icon_x"
                height={40}
                width={40}
              />
            </button>
          </p>
        </div>
        <div className="md:mt-[32px] md:p-[32px] p-[20px] bg-[#E9F8F9] rounded-[20px] h-auto md:h-[118px] border-[2px] border-main">
          {isMobile ? (
            <>
              {' '}
              <div className="flex flex-col items-start gap-2">
                <p className="font-bold text-[18px] md:text-[24px] text-main">
                  {paymentOrderRequest.productName} 플랜
                </p>
                {isInvalidCode ||
                (isAffiliateBenefit &&
                  paymentOrderRequest.subscriptionType === 'annual') ? (
                  <>
                    <span className="mr-4">
                      {/* 기존 가격 */}
                      <s className="font-bold text-[24px] md:text-[32px] text-[#8F929B]">
                        {originPrice.toLocaleString()}원
                      </s>
                    </span>
                    <p>
                      <span className="font-bold text-[24px] md:text-[32px]">
                        {modifyPrice.toLocaleString()}원
                      </span>

                      {paymentOrderRequest.subscriptionType === 'annual' ? (
                        <span className="text-[14px] md:ext-[18px]">
                          /1년(VAT 별도)
                        </span>
                      ) : (
                        <span className="text-[14px] md:ext-[18px]">
                          /월(VAT 별도)
                        </span>
                      )}
                    </p>
                    {/* 할인된 가격 */}
                  </>
                ) : (
                  <>
                    {' '}
                    <span className="font-bold text-[24px] md:text-[32px]">
                      {originPrice.toLocaleString()}원
                    </span>
                    {paymentOrderRequest.subscriptionType === 'annual' ? (
                      <span className="text-[14px] md:ext-[18px]">
                        /1년(VAT 별도)
                      </span>
                    ) : (
                      <span className="text-[14px] md:ext-[18px]">
                        /월(VAT 별도)
                      </span>
                    )}
                  </>
                )}
              </div>
            </>
          ) : (
            <>
              {' '}
              <div className="flex flex-col md:flex-row items-center gap-[32px]">
                <p className="font-bold text-[18px] md:text-[24px] text-main">
                  {paymentOrderRequest.productName} 플랜
                </p>
                <p className="">
                  {isInvalidCode ||
                  (isAffiliateBenefit &&
                    paymentOrderRequest.subscriptionType === 'annual') ? (
                    <>
                      <span className="mr-4">
                        {/* 기존 가격 */}
                        <s className="font-bold text-[24px] md:text-[32px] text-[#8F929B]">
                          {originPrice.toLocaleString()}원
                        </s>
                      </span>
                      <span className="font-bold text-[24px] md:text-[32px]">
                        {modifyPrice.toLocaleString()}원
                      </span>

                      {paymentOrderRequest.subscriptionType === 'annual' ? (
                        <span className="text-[14px] md:ext-[18px]">
                          /1년(VAT 별도)
                        </span>
                      ) : (
                        <span className="text-[14px] md:ext-[18px]">
                          /월(VAT 별도)
                        </span>
                      )}
                      {/* 할인된 가격 */}
                    </>
                  ) : (
                    <>
                      {' '}
                      <span className="font-bold text-[24px] md:text-[32px]">
                        {originPrice.toLocaleString()}원
                      </span>
                      {paymentOrderRequest.subscriptionType === 'annual' ? (
                        <span className="text-[14px] md:ext-[18px]">
                          /1년(VAT 별도)
                        </span>
                      ) : (
                        <span className="text-[14px] md:ext-[18px]">
                          /월(VAT 별도)
                        </span>
                      )}
                    </>
                  )}
                </p>
              </div>
            </>
          )}
        </div>{' '}
        <div className="flex flex-col mt-[24px]">
          <div className="relative mt-[16px]  bg-[#F6F8FB] p-5 rounded-[20px]">
            <input
              className=" w-full  md:h-[83px] border-[1px] p-5  rounded-[10px] md:px-[32px] "
              placeholder="할인 코드 입력"
              onChange={(e) => {
                setInvitationCode(e.target.value)
              }}
              value={isAffiliateBenefit ? '' : invitationCode}
              disabled={isInvalidCode || isAffiliateBenefit || isCouponBenefit}
            />
            <div className="flex gap-[16px] absolute right-8 bottom-8 md:bottom-9 items-center ">
              <button onClick={handleInvalidCode}>
                <img
                  className="w-[24px] h-[24px]  md:w-[32px] md:h-[32px]"
                  src="assets/img/icon_circle_x.png"
                  alt="x"
                />
              </button>
              <button
                className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[20px] py-[10px]  md:px-[24px] md:py-[16px] md:w-[76px] md:h-[51px]   flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                onClick={handleDiscount}
                disabled={
                  !invitationCode.length || isInvalidCode || isAffiliateBenefit
                }
              >
                확인
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white mt-[24px]">
          <p className="font-bold md:text-[32px] text-[24px] w-full text-left mb-2">
            금액 안내
          </p>
          <div className=" bg-[#F6F8FB] h-auto rounded-[20px] md:px-[32px] px-5 py-[24px] flex flex-col justify-between gap-[17px]">
            <div className="flex justify-between">
              <p className="font-bold md:text-[24px] text-[20px]">금액 상세</p>
              <p className="font-bold md:text-[24px] text-[20px]">
                {modifyPrice.toLocaleString()}원
              </p>
            </div>
            <div className="flex gap-[10px]">
              <div className="w-[4px] h-auto bg-[#D4D9DF]"></div>
              <div className="flex flex-col w-full text-[#8F929B] font-bold">
                {upgrade ? (
                  <>
                    <p className="w-full flex justify-between">
                      <span>연간 플랜 금액</span>
                      <span> {originPrice.toLocaleString()}원</span>
                    </p>
                    <p className="w-full flex justify-between">
                      <span>기존 플랜 결제 금액</span>
                      <span> -{monthPrice?.toLocaleString()}원</span>
                    </p>
                    <p className="w-full flex justify-between">
                      <span>할인 코드</span>
                      <span>
                        {' '}
                        {discountValue !== 0 ? '-' : ''}
                        {discountValue.toLocaleString()}원
                      </span>
                    </p>
                    <p className="w-full flex justify-between">
                      <span>포인트</span>
                      <span>
                        {' '}
                        {Number(point) !== 0 ? '-' : ''}
                        {Number(point).toLocaleString()}원
                      </span>
                    </p>
                  </>
                ) : (
                  <>
                    {' '}
                    <p className="w-full flex justify-between">
                      <span>플랜 가격</span>
                      <span> {originPrice.toLocaleString()}원</span>
                    </p>
                    <p className="w-full flex justify-between">
                      {isAffiliateBenefit ? (
                        <span>제휴 할인</span>
                      ) : isCouponBenefit ? (
                        <span>미션 완료 할인</span>
                      ) : (
                        <span>할인 코드</span>
                      )}
                      <span>
                        {' '}
                        {discountValue !== 0 ? '-' : ''}
                        {discountValue.toLocaleString()}원
                      </span>
                    </p>
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <p className="font-bold md:text-[24px] text-[20px] ">
                VAT(부가세 10%)
              </p>
              <p className="font-bold md:text-[24px] text-[20px]">
                {modifyVat.toLocaleString()}원
              </p>
            </div>
          </div>
        </div>
        <div className="bg-[#AFECEC] rounded-[20px] mt-[8px] flex p-[20px] md:flex-row justify-between items-center md:px-[32px] md:py-[24px] h-[78px] ">
          <p className="text-[18px] md:text-[24px] font-bold">총 결제금액</p>
          <p className="text-[24px] font-bold">
            {' '}
            {totalPrice.toLocaleString()}원
          </p>
        </div>
        {inviteBenefit && (
          <div className="bg-[#F6F8FB] rounded-[20px] mt-11 flex p-5 flex-col md:flex-row justify-between items-center md:px-[32px] md:py-[24px] md:h-[78px] gap-3 md:gap-0">
            <p className="text-[20px] text-left w-full ">
              <span className="font-bold">초대 코드 적용 혜택</span> (최초 1회)
            </p>
            <p className="text-[20px] font-bold  text-right w-full">
              {' '}
              +{(
                modifyPrice / inviteBenefit.bonusPointRate
              ).toLocaleString()}{' '}
              캐시 보너스
            </p>
          </div>
        )}
        {/* <div className="flex flex-col mt-[24px] ">
          <p className="w-full text-left text-[24px] font-bold">포인트 적립 </p>
          <div className="point bg-[#F6F8FB] rounded-[20px] mt-[16px]  flex flex-col p-[20px]  md:px-[32px] md:py-[24px]  relative">
            <input
              className=" w-full h-[83px] border-[1px] p-[16px]  rounded-[20px] px-[32px]"
              placeholder="초대 코드를 입력해주세요"
              onChange={(e) => {
                setInvitationCode(e.target.value)
              }}
              value={invitationCode}
              disabled={isInvalidCode}
            />

            <div className="flex gap-[16px] absolute right-14 top-10 items-center ">
              <button onClick={handleInvalidCode}>
                <img
                  className=" w-[32px] h-[32px]"
                  src="assets/img/icon_circle_x.png"
                  alt="x"
                />
              </button>
              <button
                className="bg-[#6B8ACB] hover:bg-[#8FABE5] active:scale-95 text-white px-[24px] py-[16px] md:w-[76px] md:h-[51px] w-[40px]  flex items-center justify-center rounded-[10px] font-bold disabled:opacity-[0.3]"
                onClick={handleDiscount}
                disabled={!invitationCode.length || isInvalidCode}
              >
                확인
              </button>
            </div>
            <div className="flex justify-between font-bold text-[20px] mt-[16px]">
              <p>적립예정 포인트</p>
              <p>0 P</p>
            </div>
          </div>
        </div> */}
        <div className="box_section">
          <div id="payment-widget" />
        </div>
        <div className="w-full bg-[#F6F8FB]  p-[20px] rounded-[20px] flex justify-start flex-col items-start border-[2px] border-[#D9DEE8]">
          <p className="font-bold text-[18px] p-[10px]">환불 규정</p>
          <ul className="list-disc pl-8 text-left">
            <li>
              결제 후 사용이력(영상생성 요청 이력)이 전혀 없는 경우, 결제 후 7일
              이내에 환불이 가능합니다.
            </li>
            <ul className=" list-circle  pl-4 text-left">
              <li>
                단, 7일이 지난 경우에는 취소수수료(구매한 가격의 10%)를 공제한
                후 환불되나, 이 경우에도 사용 유효기간이 지난 부분에 대해서는
                환불이 불가능합니다.
              </li>
            </ul>
            <li>월 결제 플랜은 중도해지가 불가능합니다.</li>
            <li>
              {`연 결제 플랜은 장기적으로 계속 이용하는 고객을 위하여 혜택을
              드리는 것으로, 중도 해지시 차감되는 금액은 취소수수료 외에
              할인적용 전 가격(월 결제 가격)을 기준으로, 중도해지를 요청한 날이
              속한 결제달에 플랜이 만료되며(예: 1/12에 연 결제 플랜을 시작하고
              3/20에 중도해지를 요청한 경우, 3/20이 속한 결제달인 3/12~4/11이
              만료되는 4/11에 플랜이 만료됩니다), 만료 후 남은 개월수에 비례하여
              아래의 계산식에 따라 차감하여 환불합니다. 차감할 금액이 최초
              결제금액을 초과할 경우에는 환불이 불가능합니다.`}
            </li>
            <ul className=" list-circle  pl-4 text-left">
              {' '}
              <li>
                환불금액 = 결제금액 - 취소수수료(결제금액 * 10%) - [ (플랜
                개시일부터 플랜 조기만료일까지의 개월수) / 12 ] * 할인 전
                가격(월 결제 가격) * 12
              </li>
            </ul>
          </ul>
        </div>
        <div className="flex  items-center justify-center gap-3 mt-[24px]">
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleChange}
            className="w-[20px] h-[20px] shrink-0"
          />
          <p className="text-[#8F929B] text-[18px] text-left">
            [필수] 패스트컷 결제 서비스와 위 환불규정에 동의합니다
          </p>
        </div>
        <div className="w-full bg-main my-[24px] h-[60px] flex items-center justify-center rounded-[40px] text-[white] font-bold">
          <button
            className="w-full h-full text-[18px]"
            disabled={!isChecked}
            onClick={() => {
              handelPayment()
            }}
          >
            {totalPrice.toLocaleString()}원 결제하기
          </button>
        </div>
      </div>
      {isModal && (
        <Modal isModal={isModal}>
          <div className="bg-white w-[400px] h-[184px] p-[20px] border-t rounded-t-[20px]">
            <div className="flex flex-col items-center justify-center h-full gap-[24px]">
              <p className="font-bold text-[18px]">{errorText.title}</p>
              <div className="flex flex-col leading-[22px]">
                <p className=" text-[14px] ">{errorText.subtitle}</p>
              </div>
            </div>
          </div>
          <div className="w-[400px] text-[14px]  h-[60px]  flex justify-center items-center">
            <button
              className="text-white w-[100%] h-full bg-main rounded-b-[20px] border-b border-main font-bold"
              onClick={() => {
                setModal((prev) => !prev)
              }}
            >
              확인
            </button>
          </div>
        </Modal>
      )}
    </div>
  )
}
